import { AvForm } from "availity-reactstrap-validation"
import { CustomDatePicker, CustomSelect, CustomSelectAsync } from "components/Common"
import CustomSearchBar from "components/Common/CustomTable/SearchBar"
import { parameterCode } from "constant"
import { getCodesByParameterId } from "helpers/app-backend"
import { convertDateFormat, getI18nextLng } from "helpers/utilities"
import React, { forwardRef, useEffect, useImperativeHandle, useState } from "react"
import { withTranslation } from "react-i18next"
import { connect } from "react-redux"
const HeaderButtons = forwardRef(
    ({
        t,
        onSubmit,
    }, ref) => {
        let lang = getI18nextLng()
        const [searchText, setSearchText] = useState('')
        const [serviceTypes, setServiceTypes] = useState([])
        const [serviceType, setServiceType] = useState('')
        const [dates, setDates] = useState([convertDateFormat(new Date(), "YYYY-MM-DD"), convertDateFormat(new Date(), "YYYY-MM-DD")])
        useEffect(() => {
            getServiceType()
        }, [])
        useImperativeHandle(ref, () => ({
            GetDataFilter: () => {
                return getData()
            },
        }));
        const getServiceType = async () => {
            const query = { lang }
            let res = await getCodesByParameterId(parameterCode.SERVICE_TYPE, query);
            res?.map(_item => {
                _item.value = _item.code
                _item.label = `${_item.message}`
                return _item
            });
            res = res || []
            // res.unshift({
            //     value: '',
            //     label: t("ServiceType")
            // })
            setServiceTypes(res)
        }
        const getData = (isClear = false) => {
            return {
                start: dates[0] || convertDateFormat(new Date(), "YYYY-MM-DD"),
                end: dates[1] || convertDateFormat(new Date(), "YYYY-MM-DD"),
                partnerCode: serviceType,
                search: isClear ? '' : searchText
            }
        }

        useEffect(() => {
            onSubmit && onSubmit(getData())
        }, [serviceType, dates])


        return (
            <React.Fragment>
                <AvForm>
                    <div className="right-pane-result-detail row" style={{ paddingTop: '10px' }}>
                        <div className="col-12 mb-2">
                            <CustomSearchBar
                                onSearch={(e) => {
                                    setSearchText(e.target.value)
                                }}
                                searchText={searchText}
                                placeholder={t("Search")}
                                onClear={() => {
                                    setSearchText('')
                                    onSubmit && onSubmit(getData(true))
                                }}
                                onSubmit={(e) => {
                                    e.preventDefault()
                                    onSubmit && onSubmit(getData())
                                }}
                            />
                        </div>
                        <div className="col-12 d-flex">
                            <div className="mb-2 flex-1" style={{ marginRight: '2px' }}>
                                <CustomDatePicker
                                    name="requestDate"
                                    label={''}
                                    format={"d-m-Y"}
                                    value={dates}
                                    onChangeHandler={(date) => {
                                        if (date.time.length == 2) {
                                            setDates([convertDateFormat(date.time[0], "YYYY-MM-DD"), convertDateFormat(date.time[1], "YYYY-MM-DD")])
                                        }
                                    }}
                                    closeOnSelect={true}
                                    mode='range'
                                    maxDate={new Date(new Date().setHours(0, 0, 0, 0))}
                                    conjunction={'  '}
                                />
                            </div>
                            <div className="mb-2 flex-1" style={{ marginLeft: '2px' }}>
                                <CustomSelectAsync
                                    name="partnerCode"
                                    value={serviceType}
                                    valueName={""}
                                    code={parameterCode.PARTNER_ORGANIZATION}
                                    label={""}
                                    placeholder={t("resultPage:Partner Send")}
                                    onChange={(e, vals, a, values) => {
                                        // setModel({ ...model, groupCode: vals[0] })
                                        // onChangeGroup(values[0])
                                        setServiceType(vals[0] || '')
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                </AvForm>
            </React.Fragment>
        )
    })

const mapStateToProps = ({ }) => ({
})

const mapDispatchToProps = dispatch => ({
})
HeaderButtons.displayName = 'HeaderButtons';
export default withTranslation(["common", "resultPage"], { withRef: true })(connect(
    mapStateToProps,
    mapDispatchToProps, null, { forwardRef: true }
)(HeaderButtons))