import { AvForm } from "availity-reactstrap-validation"

import {
    CustomButton,
    CustomModal
} from "components/Common"
import { ModalBody, ModalFooter } from "reactstrap"
import { read, utils } from "xlsx"

import { ImportTests, downloadImportTestTemplate } from "helpers/app-backend"
import { useRef, useState } from "react"
import { withTranslation } from "react-i18next"

const ImportTest = ({
    modal,
    toggle,
    onValidSubmit,
    data,
    t,
}) => {
    const formEl = useRef()
    const [fileName, setFileName] = useState("");
    const [dataSource, setDataSource] = useState([]);
    const [msg, setMsg] = useState('')
    const [isSuccess, setIsSuccess] = useState(false);
    const handleImport = e => {
        setMsg("")
        const files = e.target.files
        if (files.length) {
            let rowChoose = []
            const file = files[0]
            const reader = new FileReader()
            reader.onload = event => {
                const wb = read(event.target.result)
                const sheets = wb.SheetNames
                if (sheets.length) {
                    const rows = utils.sheet_to_json(wb.Sheets[sheets[0]])
                    rows.forEach((item, index) => {
                        const first = item?.TestName.indexOf('[')
                        const last = item?.TestName.lastIndexOf(']')
                        let customName = ''
                        if (first !== -1 && last !== -1) {
                            customName = item?.TestName.slice(first + 1, last)
                        }

                        let i = {
                            "testCode": item.TestCode,
                            "testName": item.TestName.replace(`[${customName}]`, '').trimEnd(),
                            // "shortName": item.ShortName || '',
                            "customName": customName,
                            // "quickCode": item.QuickCode || '',
                            "sampleType": item.SampleType || '',
                            "Unit": item.Unit || '',
                            "SubSID": _.isNumber(item.SubSID) ? (Number(item.SubSID) == 0 ? null : Number(item.SubSID)) : null,
                            "category": item.Category || '',
                            "type": _.isNumber(item.Type) ? item.Type : 0,
                            "displayOrder": _.isNumber(item.DisplayOrder) ? item.DisplayOrder : 0,
                            // "tags": "",
                            // "remark": item.Remark || "",
                            "inUse": true
                        }
                        rowChoose.push(i)
                    })
                    setDataSource([...rowChoose])
                }
            }
            reader.readAsArrayBuffer(file)
        }
        else {
            resetData()
        }
    }
    const resetData = () => {
        setDataSource([]);
        setFileName("");
        setMsg('')
        setIsSuccess(false)
        document.getElementById("importExcelTest").value = null;
    }

    const DownloadTemplate = async () => {
        const fileData = await downloadImportTestTemplate()
        const blob = new Blob([fileData])
        saveAs(blob, "ImportTestTemplate.xlsx")
    }

    const submit = async () => {
        let res = await ImportTests({ tests: dataSource })
        if (res == '')
            setIsSuccess(true)
        else
            setMsg(res)
    }
    return (
        <CustomModal modal={modal} title={t("Import Test")} onToggle={toggle} size="xl" centered={false}>
            <ModalBody>
                <AvForm
                    ref={formEl}
                    id="testForm"
                    onValidSubmit={() => submit()}
                    model={data}
                >
                    <div className="row">
                        <div className="col-12">
                            <input
                                id="importExcelTest"
                                name="importTestTemplate"
                                type="file"
                                className="d-none"
                                accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                                onChange={e => {
                                    setFileName(e.target.files[0]?.name || "")
                                    handleImport(e)
                                }}
                            />
                            <div className="import-upload-table-name">
                                {fileName != "" &&
                                    <>
                                        <span>{fileName}</span>
                                        <div className="import-upload-table-name-icon" onClick={() => {
                                            resetData()
                                        }}>
                                            <i className="fas fa-times"></i>
                                        </div>
                                    </>
                                } &nbsp;
                            </div>
                            {msg != '' &&
                                <span className="text-danger">{msg}</span>
                            }
                            {isSuccess == true &&
                                <span className="text-success">{t("message:Succeeded", { field: `Upload` })}</span>
                            }
                        </div>
                        <div className="col-12">
                            <div className="d-flex justify-content-center">
                                <div style={{ marginRight: '10px' }}>
                                    <CustomButton
                                        isEdit
                                        color="primary"
                                        outline
                                        onClick={() => {
                                            resetData();
                                            $(`#importExcelTest`).click()
                                        }}
                                    >{t("Import")}</CustomButton>
                                </div>
                                <div>
                                    <CustomButton color="primary" outline onClick={() => {
                                        DownloadTemplate()
                                    }}>
                                        <i className="fa fa-download"></i>
                                        {t("Template")}
                                    </CustomButton>
                                </div>
                            </div>
                        </div>
                    </div>
                </AvForm>

            </ModalBody>
            <ModalFooter>
                <CustomButton
                    text={t("common:Cancel")}
                    type="button"
                    onClick={toggle}
                    data-dismiss="modal"
                    className="button-width"
                />

                <CustomButton
                    text={t("common:Upload")}
                    type="submit"
                    color="primary"
                    disabled={dataSource.length == 0}
                    onClick={() => formEl?.current?.submit()}
                    className="save-user button-width"
                    isEdit
                />
            </ModalFooter>
        </CustomModal>
    )
}

export default withTranslation(["testPage", "common", "message"])(ImportTest)
