import { DynamicForm, SystemInformation } from "components/Common"
import { ModuleIds } from "constant/utility"
import { withTranslation } from "react-i18next"
import { Col, Row } from "reactstrap"
const RESOURCE = ModuleIds.Patient

const ViewMode = ({ data, t, fields }) => {
  data.createdBy = data.CreatedBy
  data.createdDate = data.CreatedDate
  data.updatedBy = data.UpdatedBy
  data.updatedDate = data.UpdatedDate
  return (
    <Row>
      <Col sm="12">
        <Row>
          <DynamicForm items={fields} resource={RESOURCE} isView />
        </Row>
      </Col>
      <Col sm="12" className="px-0">
        <SystemInformation t={t} data={data} />
      </Col>
    </Row>
  )
}

export default withTranslation(["partyPage", "common"])(ViewMode)
