import { ButtonDropdownGroup, CustomBootstrapTable } from "components/Common";
import { ModuleIds, ResultStateBookmark, ResultStateBookmarkColor, parameterCode } from "constant";
import { convertDateFormat, convertDateFormat_VN, getColorBookmark, indexCalculator } from "helpers/utilities";
import { useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import FilterForm from "./FilterForm";
import { ButtonDropdown, DropdownItem, DropdownMenu, DropdownToggle } from "reactstrap";
import CustomButtonRight from "./CustomButtonRight";
import CustomSIDHover from "components/Common/Input/CustomSIDHover";
import CustomTAT from "components/Common/Input/CustomTATnew";
import { getGeneralSetting } from "helpers/app-backend/general_setting_backend_helper";

const RESOURCE = ModuleIds.TestSend;

const TestResultTable = ({
    onSelect,
    onSelectAll,
    testResults,
    onSearch,
    onSort,
    onRefresh,
    onPageChange,
    paging,
    onSizePerPageChange,
    onSubmitFilter,
    loading,
    t,
    updatedTime,
    onChangeResult,
    testResult,
    onGeneralSetting,
}) => {
    const [resultId, setResultId] = useState('')
    const [data, setData] = useState([]);
    const [model, setModel] = useState({
        search: "",
        start: "",
        end: "",
        physicianId: "",
        departmentId: "",
        state: "",
        printed: null,
    });
    const [warningSampleDelay, setWarningSampleDelay] = useState(0)
    const searchQuery = useSelector(state => state.testResult?.searchQuery);
    const defaultSortValue = { dataField: "requestDate", order: "desc" }
    const onResetHandler = () => {
        const initModel = {
            search: "",
            start: "",
            end: "",
            physicianId: "",
            departmentId: "",
            state: "",
            printed: null,
        }
        setModel(initModel)
    }

    useEffect(() => {
        setModel(searchQuery || {})
    }, [searchQuery])

    useEffect(() => {
        setResultId(testResult.id)
    }, [testResult])

    useEffect(() => {
        setData(testResults)
    }, [testResults])

    const activeStyle = {
        fontWeight: 'bold', color: '#556ee6'
    }
    const checkActiveRow = (row) => {
        if (row.id == resultId) {
            return activeStyle;
        }
        return {}
    }
    const GetGeneralSettingList = async () => {
        const userInfo = JSON.parse(localStorage.getItem("userInfo"))
        const res = await getGeneralSetting(userInfo.company)
        if (res && res.length > 0) {
            onGeneralSetting && onGeneralSetting(res)
            const time = res.find(x => x.fieldName == "WarningSampleDelay")?.fieldValue || 0
            setWarningSampleDelay(time)
        }
    }
    useEffect(() => {
        GetGeneralSettingList()
    }, [])

    const getColor = (item) => {
        if (item.valid == true) {
            return ResultStateBookmarkColor.Green
        } else if (item.sent == true || item.completed == true) {
            return ResultStateBookmarkColor.Yellow
        }
        else {
            return 'red';
        }
    }
    const columns = [
        {
            dataField: "id",
            text: "#",
            hidden: true,
            formatter: (cellContent, row, index) => {
                return <span style={checkActiveRow(row)}>{cellContent}</span>
            },
        },
        // {
        //     dataField: "SEQ",
        //     text: "#",
        //     headerStyle: { width: "30px" },
        //     formatter: (cellContent, row, index) => {
        //         return <span style={checkActiveRow(row)}>{indexCalculator(paging.page, paging.size, index + 1)}</span>
        //     },
        // },
        {
            dataField: "tag",
            text: t(""),
            headerStyle: { width: "25px" },
            align: 'center',
            headerAlign: 'center',
            formatter: (cellContent, row, index) => {
                return <div className="d-flex" style={{ alignItems: 'center' }}>
                    <div style={{ width: '10px', backgroundColor: 'green' }}></div>
                    <span><i style={{ color: `${getColor(row)}` }} className="fas fa-circle"></i></span>
                </div>
            },
        },
        {
            dataField: "requestDate",
            text: t("common:Date"),
            headerStyle: { width: "100px" },
            formatter: (cellContent, row, index) => {
                return <span style={checkActiveRow(row)}>{convertDateFormat(row.createdDate, "YYYY-MM-DD")}</span>
            },
        },
        {
            dataField: "SID",
            text: t("SID"),
            headerStyle: { width: "50px" },
            formatter: (cellContent, testResult, indexParent) => {
                return <CustomSIDHover placement="bottom" data={testResult.sampleIds} indexParent={indexParent} emergency={testResult.emergency} />
            },
        },
        {
            dataField: "patientName",
            text: t("testRequestPage:Full Name"),
            formatter: (cellContent, row, index) => {
                //return <span style={checkActiveRow(row)}>{cellContent && `${cellContent} ${row.dob ? (' - ' + convertDateFormat(row.dob, "YYYY")) : ''}`}</span>
                return <span style={checkActiveRow(row)}>{cellContent && `${cellContent}`}</span>
            },
        },
        {
            dataField: "age",
            text: t("DOB"),
            formatter: (cellContent, item, index) => item.dob && <div>{convertDateFormat_VN(item.dob, "DD-MM-YYYY")}</div>
            ,
        }
    ]

    return (
        <div className="result-right-frame-table">
            <CustomBootstrapTable
                columns={columns}
                // search
                data={data}
                paging={paging}
                onSelect={onSelect}
                onSelectAll={onSelectAll}
                onSearch={onSearch}
                onSort={onSort}
                onRefresh={onRefresh}
                filterForm={() => <FilterForm model={model} />}
                onReset={onResetHandler}
                onPageChange={onPageChange}
                onSizePerPageChange={onSizePerPageChange}
                onSubmitFilter={onSubmitFilter}
                searchText={model.search}
                loading={loading}
                updatedTime={updatedTime}
                resource={RESOURCE}
                keyField="id"
                defaultSortValue={defaultSortValue}
                showSelectRow={false}
                isEnableExport={false}
                TableTitle={t("testResultPage:Send Result List")}
                onRowClick={(e, row, index) => {
                    if (data?.length > 1) {
                        setResultId(row.id)
                        setData(data.map(x => ({ ...x, selected: x.id == row.id })))
                        onChangeResult(row.requestId)
                    }
                }}
                headerClasses={'table-light result-test-color'}
                rowClasses={(row, index) => {
                    const data = row.tests?.find(item => item?.vendorCode)
                    return data != null && 'result-test-color'
                }}
            />
        </div>
    )
}

export default withTranslation(["common", "testResultPage", "testRequestPage"])(TestResultTable)