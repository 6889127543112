import { Check, CustomBootstrapInlineTable } from "components/Common"
import CustomPopover from "components/Common/Input/CustomPopover"
import { ModuleIds, parameterCode, permissionType, RESULT_STATE_Text, TestRequest_State, TestRequest_Test_Type } from "constant"
import { useEffect, useState } from "react"
import { withTranslation } from "react-i18next"
import ReactTooltip from "react-tooltip"

const RESOURCE = ModuleIds.PatientVisit

const TestsTabTable = ({
    onSelect,
    onSelectAll,
    data,
    onEdit,
    onDelete,
    t,
    loading,
    onEditSampleType,
    onGetTestRequestSample,
    disabled,
    testRequestSamples,
    ...rest
}) => {
    const [subProfileData, setSubProfileData] = useState([])
    useEffect(() => {
        const testCodes = data.filter(item => item.state != '2' && (item.children && item.children.length > 0 ? item.children[0].state != '2' : true))
        setSubProfileData(testCodes)
    }, [data])

    const isCancel = (code) => parseInt(code?.state) === RESULT_STATE_Text.Canceled ? { textDecoration: 'line-through' } : {}
    const checkDelete = (sampleType) => {
        return testRequestSamples?.some(sample =>
            (sample.state > RESULT_STATE_Text.Collected || sample.state === RESULT_STATE_Text.Canceled) &&
            sample.sampleType === sampleType
        );
    };
    const columns = [
        {
            dataField: "id",
            text: "id",
            hidden: true,
            searchable: false,
        },
        {
            dataField: "idx",
            text: "#",
            searchable: false,
            headerStyle: { width: "50px" },
            formatter: (cellContent, code, index) => {
                return index + 1
            },
        },
        {
            dataField: "name",
            text: t("Profile/Test"),
            style: { paddingLeft: "0px", paddingRight: "0px", width: "308px" },
            headerStyle: { width: "250px" },
            formatter: (cellContent, code, index) => (
                <>
                    {
                        (code.type == TestRequest_Test_Type.PROFILE_GROUP) &&
                        <div style={isCancel(code)}>
                            <i className="fas fa-arrow-right"
                                style={code.children && code.children.length > 0 ? { color: "#556ee6" } : {}}></i>
                            <span className="px-2" style={{ fontWeight: 'bold' }}>{code.name}</span>
                        </div>
                    }
                    {
                        (code.type == TestRequest_Test_Type.PROFILE) &&
                        <div style={isCancel(code?.children[0])}>
                            <i className="fas fa-arrow-right"
                                style={code.children && code.children.length > 0 ? { color: "#556ee6" } : {}}></i>
                            <span className="px-2" style={{ fontWeight: 'bold' }}>{code.name}</span>
                        </div>
                    }
                    {
                        (code.type == TestRequest_Test_Type.TEST) &&
                        <div style={isCancel(code)}>
                            <span>-</span>
                            <span className="px-2">{code.name}</span>
                        </div>
                    }
                </>
            ),
        },
        {
            dataField: "code",
            text: t("Code"),
            style: { paddingLeft: "0px", paddingRight: "0px" },
            headerStyle: { width: "90px", paddingLeft: "0px", paddingRight: "0px" },
        },
        {
            dataField: "subSID",
            text: t("SubSID"),
            style: { paddingLeft: "0px", paddingRight: "0px", textAlign: 'center' },
            headerStyle: { width: "30px" },
            formatter: (cellContent, item, index) => {
                return (
                    <span>
                        {cellContent != 0 && cellContent}
                    </span>
                )
            },
        },
        {
            dataField: "categoryName",
            text: t("Category"),
            headerStyle: { width: "80px", paddingLeft: "0px", paddingRight: "0px" },
            formatter: (cellContent, code, index) => {
                return (
                    <>
                        {(code.type != TestRequest_Test_Type.PROFILE_GROUP) &&
                            <div
                                className="px-2 d-flex badge-gender"
                                style={{ width: '50px', height: '30px', backgroundColor: "#d4dbf9", color: "#556ee6", justifyContent: 'center' }}
                                id={`category-${index}`}
                                data-tip
                                data-for={`category-${index}`}
                            >
                                {code.category}
                                <ReactTooltip place="bottom" id={`category-${index}`} effect="solid" >
                                    {code.categoryName}
                                </ReactTooltip>
                            </div>
                        }
                    </>
                )
            },
        },
        {
            dataField: "sampleType",
            text: t("Sample Type"),
            headerStyle: { width: "180px", paddingLeft: "0px", paddingRight: "0px" },
            formatter: (cellContent, code, index) => {
                return <span>{(code?.children && code?.children[0]?.sampleTypeName) || code?.sampleTypeName}</span>
            },
        },
        {
            dataField: "action",
            text: "",
            isDummyField: true,
            headerStyle: { width: "65px" },
            style: { textAlign: "center", width: "65px" },
            formatter: (cellContent, code, index) => {
                return (<>
                    {
                        checkDelete(code?.sampleType || code?.children[0]?.sampleType) ?
                            <span></span> :
                            <Check resource={RESOURCE} permission={permissionType.D}>
                                <div href="#" style={{
                                    position: 'relative',
                                    width: 20,
                                    height: 20,
                                    margin: '0 auto'
                                }}
                                    onClick={() => onDelete(code)}
                                >
                                    <i style={{ position: 'absolute', top: -5, left: 0 }}
                                        className="mdi mdi-trash-can font-size-20 text-danger me-1" />{" "}
                                </div>
                            </Check>
                    }
                </>
                )
            },
        }
    ]
    const columnChild = (rowIndex) => [
        {
            dataField: "id",
            text: "id",
            hidden: true,
            headerAttrs: {
                hidden: true
            }
        },
        {
            dataField: "idx",
            text: "#",
            headerStyle: { width: "50px" },
            formatter: (cellContent, code, index) => {
                return <span>{`${rowIndex + 1}.${index + 1}`}</span>
            },
        },
        {
            dataField: "name",
            text: t("Profile/Test"),
            style: { paddingLeft: "0px", paddingRight: "0px", width: "308px" },
            headerStyle: { width: "250px" },
            formatter: (cellContent, code, index) => (
                <>
                    {
                        (code.type == TestRequest_Test_Type.PROFILE) &&
                        <div style={isCancel(code)} className="mx-3">
                            <i className={`fas fa-arrow-right ${code.code}-${code.id}`}
                                style={code.children && code.children.length > 0 ? { color: "#556ee6" } : {}} >
                            </i>
                            <span className="px-2" style={{ fontWeight: 'bold' }}>{code.name}</span>
                        </div>
                    }
                    {
                        (code.type == TestRequest_Test_Type.TEST && !code.groupCode) &&
                        <div style={isCancel(code)} className="mx-3">
                            <span>-</span>
                            <span className="px-2">{code.name}</span>
                        </div>
                    }
                    {
                        (code.type == TestRequest_Test_Type.TEST && code.groupCode) &&
                        <div style={isCancel(code)} className="mx-5">
                            <span>-</span>
                            <span className="px-2">{code.name}</span>
                        </div>
                    }
                </>
            ),
        },
        {
            dataField: "code",
            text: t("Code"),
            style: { paddingLeft: "0px", paddingRight: "0px" },
            headerStyle: { width: "90px", padding: "10px" },
        },
        {
            dataField: "subSID",
            text: t("SubSID"),
            style: { paddingLeft: "0px", paddingRight: "0px", textAlign: 'center' },
            headerStyle: { width: "30px" },
            formatter: (cellContent, item, index) => {
                return (
                    <span>
                        {cellContent != 0 && cellContent}
                    </span>
                )
            },
        },
        {
            dataField: "category",
            text: t("Category"),
            headerStyle: { width: "80px", paddingLeft: "0px", paddingRight: "0px" },
            formatter: (cellContent, code, index) => {
                return <div
                    className="px-2 d-flex badge-gender"
                    style={{ width: '50px', height: '30px', backgroundColor: "#d4dbf9", color: "#556ee6", justifyContent: 'center' }}
                    id={`category-${index}`}
                    data-tip
                    data-for={`category-${index}`}
                >
                    {code.category}
                    <ReactTooltip place="bottom" id={`category-${index}`} effect="solid" >
                        {code.categoryName}
                    </ReactTooltip>
                </div>
            }
        },
        {
            dataField: "sampleType",
            text: t("Sample Type"),
            headerStyle: { width: "180px", paddingLeft: "0px", paddingRight: "0px" },
            events: {
                onClick: (cellContent, code, index) => { e => e.stopPropagation() },
            },
            formatter: (cellContent, code, index) => {
                return (
                    <>
                        {(!code.sampleType || code.isNewSampleType) && !disabled &&
                            <div onClick={e => e.stopPropagation()} >
                                <CustomPopover isSearch={true} code={parameterCode.SAMPLE_TYPES} title={code?.sampleTypeName}
                                    valueName={code?.sampleTypeName} value={code.sampleType}
                                    onChange={(item) => {
                                        onEditSampleType(code, item)
                                    }}
                                />
                            </div>


                        }
                        {code.sampleType && !code.isNewSampleType &&
                            <div>{code?.sampleTypeName}</div>
                        }
                    </>
                )
            }
        },
        {
            dataField: "action",
            text: "Action",
            isDummyField: true,
            headerStyle: { width: "65px" },
            style: { textAlign: "center", width: "65px" },
        },
    ]
    const expandRow = {
        renderer: (row, rowIndex) => {
            return (
                row.hasChildren && row.hasChildren === true ?
                    <div className="table-expand-custom-header tableinline-scrollable">
                        <CustomBootstrapInlineTable
                            columns={columnChild(rowIndex)}
                            data={row.children}
                            onSelect={onSelect}
                            onSelectAll={onSelectAll}
                            isScrollable
                            expandRow={expandRow}
                            loading={loading}
                            searchText={""}
                            draggable
                            tableHeaderClass={"col-hidden"}
                            keyField={"code"}
                            {...rest}
                        />
                    </div>
                    : null
            )
        },
        parentClassName: 'row-expanded'
    };

    return (
        <div className="table-expand-custom">
            <CustomBootstrapInlineTable
                columns={columns}
                data={subProfileData}
                onSelect={onSelect}
                onSelectAll={onSelectAll}
                isScrollable
                expandRow={expandRow}
                loading={loading}
                searchText={""}
                draggable
                keyField={"code"}
                {...rest}
            />
        </div>
    )
}

export default withTranslation(["testRequestPage", "common"])(TestsTabTable)
