import { spreadSearchQuery } from "helpers/utilities"
import { get, put, post, del, getChangeStore, getWithReport, downloadFile, downloadFileWithName, viewFileWithName } from "../api_helper2"
import { get as getWithoutError, post as postWithoutError } from "../api_helper_withoutError"

const BASE_API_URL = `${process.env.REACT_APP_MASTER_SETUP_ENDPOINT}/files`
const BASE_API_URL_LA = `${process.env.REACT_APP_LABORATORY_ENDPOINT}/global`
const BASE_API_URL_LA_RESULT = `${process.env.REACT_APP_LABORATORY_ENDPOINT}/results`
const BASE_API_URL_PRINT_SERVICE = "http://localhost:3075"

const getAllFiles = payload => {
    let q = spreadSearchQuery(payload)
    return get(`${BASE_API_URL}?${q}`)
}

const getFileById = id => {
    return downloadFile(`${BASE_API_URL_LA}/files/${id}`)
}


const getFileByIdWithName = id => {
    return downloadFileWithName(`${BASE_API_URL_LA}/files/${id}/GetFileByIdWithName`)
}

const getViewFileByIdWithName = id => {
    return viewFileWithName(`${BASE_API_URL_LA}/files/${id}/GetFileByIdWithName`)
}

const createFile = req => {
    const { Id } = req
    return post(`${BASE_API_URL_LA}/files?reportId=${Id}`, req)
}

const exportFile = req => {
    const { Id } = req
    return post(`${BASE_API_URL_LA}/files/export?reportId=${Id}`, req)
}

const exportFileResult = (req, data) => {
    const { ResultId } = req
    let q = spreadSearchQuery(req)
    return put(`${BASE_API_URL_LA_RESULT}/${ResultId}/export?${q}`, data)
}

const getFilePreview = (req) => {
    const { reportId } = req
    delete req.reportId
    let q = spreadSearchQuery(req)
    return getWithReport(`${BASE_API_URL_LA}/reports/${reportId}/preview?${q}`)
}

const getFilePrint = (req) => {
    const { reportId } = req
    delete req.reportId
    let q = spreadSearchQuery(req)
    return getWithReport(`${BASE_API_URL_LA}/reports/${reportId}/print?${q}`)
}

const sendDataPrint = (req) => {
    const filePathEncode = encodeURIComponent(req.filePath)
    const printerNameEncode = encodeURIComponent(req.PrinterName)
    const baseEndPointEncode = encodeURIComponent(process.env.REACT_APP_FULL_BASE_ENDPOINT)
    // const baseEndPointEncode = encodeURIComponent("https://uatonelis.viettinlab.com")
    // const quantity = req.quantity
    return post(`${BASE_API_URL_PRINT_SERVICE}/api/prints?filePath=${baseEndPointEncode + filePathEncode}&printerName=${printerNameEncode}`, req)
}

const sendDataPrintWithoutError = (req) => {
    const filePathEncode = encodeURIComponent(req.filePath)
    const printerNameEncode = encodeURIComponent(req.PrinterName)
    const baseEndPointEncode = encodeURIComponent(process.env.REACT_APP_FULL_BASE_ENDPOINT)
    // const baseEndPointEncode = encodeURIComponent("https://sr.onelis.viettinlab.com")
    // const quantity = req.quantity
    return postWithoutError(`${BASE_API_URL_PRINT_SERVICE}/api/prints?filePath=${baseEndPointEncode + filePathEncode}&printerName=${printerNameEncode}`, req)
}

const pingToPrintService = (req) => {
    return getWithoutError(`${BASE_API_URL_PRINT_SERVICE}/api/ping`)
}

const getAllPrintersName = (req) => {
    return getWithoutError(`${BASE_API_URL_PRINT_SERVICE}/api/prints`)
}

const getFilePreviewResult = (req) => {
    const { ResultId } = req
    let q = spreadSearchQuery(req)
    return put(`${BASE_API_URL_LA_RESULT}/${ResultId}/preview?${q}`)
}

const getFilePrintResult = (req) => {
    const { ResultId } = req
    let q = spreadSearchQuery(req)
    return put(`${BASE_API_URL_LA_RESULT}/${ResultId}/print?${q}`)
}

const confirmValidPrint = (req, data) => {
    const { ResultId } = req
    let q = spreadSearchQuery(req)
    return put(`${BASE_API_URL_LA_RESULT}/${ResultId}/confirm-valid-print-result?${q}`, data)
}

const approvedValidAndPublishFilePrint = (req, data) => {
    const { ResultId } = req
    let q = spreadSearchQuery(req)
    return put(`${BASE_API_URL_LA_RESULT}/${ResultId}/approved-valid-and-publish-print-result?${q}`, data)
}

const createFileResult = (req, data) => {
    const { ResultId } = req
    let q = spreadSearchQuery(req)
    return put(`${BASE_API_URL_LA_RESULT}/${ResultId}/create-report?${q}`, data)
}

const signAndPublishReport = (req, data) => {
    const { ResultId } = req
    let q = spreadSearchQuery(req)
    //return put(`${BASE_API_URL_LA_RESULT}/${ResultId}/sign-and-publish-report?${q}`, data)
    return put(`${BASE_API_URL_LA_RESULT}/${ResultId}/sign-and-publish-report-ver-sun?${q}`, data)
}

const signAndPublishReportGroup = (data) => {
    //return post(`${BASE_API_URL_LA_RESULT}/sign-and-publish-report-group`, data)
    return post(`${BASE_API_URL_LA_RESULT}/sign-and-publish-report-group-ver-sun`, data)
}

export {
    getAllFiles,
    getFileById,
    createFile,
    getFilePreview,
    getFilePrint,
    exportFile,
    getFilePrintResult,
    createFileResult,
    getFilePreviewResult,
    exportFileResult,
    sendDataPrint,
    pingToPrintService,
    getAllPrintersName,
    confirmValidPrint,
    sendDataPrintWithoutError,
    getFileByIdWithName,
    getViewFileByIdWithName,
    signAndPublishReport,
    signAndPublishReportGroup,
    approvedValidAndPublishFilePrint
}
