import { CustomButton, CustomPrintButton, CustomSID } from "components/Common"
import CustomBootstrapTableAsync from "components/Common/CustomTable/CustomBootstrapTableAsync"
import { ReportResource } from "constant"
import { getFilePrint, pingToPrintService, sendDataPrint } from "helpers/app-backend"
import { GetDataUrlReportConfig } from "helpers/utilities"
import { isEmpty } from "lodash"
import React, { useEffect, useState } from "react"
import Editable from "react-bootstrap-editable"
import { withTranslation } from "react-i18next"
import { connect, useSelector } from "react-redux"
import { withRouter } from "react-router-dom"
import { Label } from "reactstrap"
import {
  updateNumbOfLabels
} from "../../../../../../store/laboratory/testRequest/actions"
import { selectCheckboxHandler } from "helpers/utilities"
import ReactTooltip from "react-tooltip"

const BarcodeFrame = ({
  t,
  samples,
  patientVisit,
  patientDetail,
  spin,
  fetchTestRequestSample,
  onUpdateNumberOfLabel,
  toggleBarcodeSetting
}) => {
  const [rows, setRows] = useState([])
  const [row, setRow] = useState({})
  const [SID, setSID] = useState([])
  const [newSample, setNewSample] = useState([])
  const [sampleType, setSampleType] = useState([])
  const [subSIDList, setSubSIDList] = useState([])
  const { reportInfos } = useSelector(state => {
    return ({
      reportInfos: state.Authorization.reportinfo || [],
    })
  })

  useEffect(() => {

    if (samples) {
      let sampleWithSubSID = samples?.map(item => ({
        ...item,
        // subSID: item.subSID === 0 ? "" : item.subSID,
        id: `${item.id}${item.subSID}`,
        // sid: `${item.sid}-${item.subSID}`,
        sid: `${item.sid}${item.subSID !== 0 ? '-' + item.subSID : ''}`
      }));
      setNewSample(sampleWithSubSID)
    }
  }, [samples])

  const confirmElement = (
    <button type="submit"
      className="btn btn-outline-primary editable-submit btn-sm ms-2 me-1"
    ><i className="mdi mdi-check"></i></button>
  );

  const cancelElement = (
    <button type="button"
      className="btn btn-outline-primary editable-cancel btn-sm me-3">
      <i className="mdi mdi-close"></i></button>
  );

  const submitUpdateNumberOfLabel = async (item, value) => {
    await onUpdateNumberOfLabel({
      request: {
        ...item,
        numberOfLabels: value
      }, callback: {}
    })
  }

  useEffect(() => {
    setRows([])
  }, [patientVisit])

  useEffect(() => {
    if (rows) {
      // let SIDList = []
      // let subSIDLst = []
      // rows.forEach(row => {
      //   let sid = row?.sid?.toString();
      //   let subSID = row?.subSID?.toString();
      //   if (sid.endsWith(subSID)) {
      //     let newSID = sid.substring(0, sid.length - subSID.length);
      //     SIDList.push(newSID.slice(0, -1));
      //     subSIDLst.push(subSID);
      //   } else {
      //     SIDList.push(sid);
      //     subSIDLst.push(subSID);
      //   }
      // });
      setSubSIDList(rows?.map(_row => _row?.subSID))
      setSID(rows?.map(_row => _row?.sid))
      setSampleType(rows?.map((row) => row?.sampleType?.toString()))
    }
  }, [rows])

  const PrintConfig = async () => {

    const print = JSON.parse(localStorage.getItem("printConfig"))
    let printColumns = print.columns
    let printPage = ['B1', 'B2', 'B3']
    const formattedSampleType = sampleType?.map(item => `'${item}'`)
    if (reportInfos.length > 0 && !isEmpty(reportInfos[0].uri)) {
      const res = await getFilePrint({
        ...GetDataUrlReportConfig(reportInfos, ReportResource.RequestId, ""),
        SID: SID?.length > 1 ? `[${SID}]` : SID[0],
        SubSID: subSIDList?.length > 1 ? `[${subSIDList}]` : subSIDList[0],
        RequestDate: patientVisit.requestDate,
        SampleType: formattedSampleType?.length > 1 ? `[${formattedSampleType}]` : sampleType[0],
        Gender: patientVisit.gender,
        Page: printPage[printColumns - 1]
      })
      try {
        const getPingPrintService = await pingToPrintService();
        if (getPingPrintService.pingTime) {
          const dataSendPrint = await sendDataPrint({
            ...GetDataUrlReportConfig(reportInfos, ReportResource.RequestId, ""),
            "filePath": `${res.reportUrl}`,
          })
        } else {
          window.open(process.env.REACT_APP_BASE_ENDPOINT + res?.reportUrl)
        }
      }
      catch {
        window.open(process.env.REACT_APP_BASE_ENDPOINT + res?.reportUrl)
      }
    }
    else {
      console.log('failed')
    }
  }

  const onSelectCheckbox = (row, isSelected) => {
    const { rowsState, currentRow } = selectCheckboxHandler(
      rows,
      row,
      isSelected
    )
    setRows(rowsState)
    setRow(currentRow)
  }

  const onSelectAllCheckbox = rows => {
    setRows(rows)
    if (rows.length < 1) setRow({})
    else setRow(rows[rows.length - 1])
  }
  const handlePrintBarcode = () => {
    PrintConfig()
  }

  const columns = [
    {
      dataField: "id",
      text: t("Barcode"),
      // align: 'center',
      headerStyle: { width: "100px" },
      formatter: (cellContent, item, index) => {
        return (
          <CustomPrintButton
            name={"Barcode_Template_no4_Other_Report"}
            isPrint={true}
            sid={item.sid}
            subSID={item.subSID}
            sampleType={item.sampleType}
            requestDate={patientDetail.requestDate}
            reportResource={ReportResource.RequestId}
            fileId={''}
            reportInfos={reportInfos}
            gender={patientVisit.gender}
          />
        )
      }
    },
    {
      dataField: "sampleType",
      text: t("Sample Type"),
      headerStyle: { width: "100px" },
    },
    {
      dataField: "sid",
      text: "SID",
      headerStyle: { width: "100px" },
      formatter: (cellContent, item, index) => {
        return <CustomSID
          key={index}
          keyToolTip={`sampleType-${index}`}
          isToolTip={true}
          toolTip={item.sampleTypeName}
          sid={`${item.sid}${item.subSID !== 0 ? '-' + item.subSID : ''}`}
        // emergency={item?.emergency}
        />

        // <label key={index} data-tip data-for={`sampleType-${index}`}
        //   style={{
        //     color: '#5F5F5F',
        //     backgroundColor: '#fff',
        //     padding: '1px 5px',
        //     marginRight: '5px',
        //     borderRadius: '5px',
        //     border: `1px solid #808080`,
        //     minWidth: 40,
        //     textAlign: "center"
        //   }}>
        //   {item.sid}
        //   <ReactTooltip id={`sampleType-${index}`} place="top" effect="solid">
        //     {item.sampleTypeName}
        //   </ReactTooltip>
        // </label>
      },
    },
    // {
    //   dataField: "subSID",
    //   text: "Sub SID",
    //   headerStyle: { width: "100px" },
    //   formatter: (cellContent, item, index) => {
    //     return item.subSID
    //   },
    // },
    {
      dataField: "numberOfLabels",
      text: t("testRequestPage:Labels"),
      headerStyle: { width: "70px" },
      formatter: (cellContent, item, index) => {
        const idHTML = `requestSample${item.sampleType}`.replace(' ', '')
        return (<React.Fragment>
          <div id={idHTML + '_'} className={'requestSampleItemFocus'} tabIndex={9999}
            style={{ minWidth: 45, maxWidth: 150, width: "max-content", textAlign: "center", textDecoration: 'underline' }} onClick={(e) => {
              e.stopPropagation();
            }}>
            <Editable
              alwaysEditing={false}
              disabled={false}
              editText={`${item.numberOfLabels || '1'}`}
              initialValue={`${item.numberOfLabels || '1'}`}
              id={item.code}
              isValueClickable={false}
              mode="inline"
              placement="top"
              showText={false}
              type="textfield"
              renderConfirmElement={confirmElement}
              renderCancelElement={cancelElement}
              onSubmit={(value) => {
                submitUpdateNumberOfLabel(item, value)
              }}
            />
          </div>
        </React.Fragment>
        )
      },
    },
  ]
  return (
    <div id="patient-visit-sample-list-table" className="table-patient-barcode mt-1 mb-0 table-patient-visit">
      <CustomBootstrapTableAsync
        customButtonTable={() => (
          <div>
            <div className="button-items" style={{ textAlign: "right" }}>
              {/* <CustomButton className='mb-0' color="light" onClick={fetchTestRequestSample}>
                <i className={spin ? "fas fa-sync fa-spin" : "fas fa-sync-alt"}></i>
              </CustomButton> */}

              <CustomButton className='mb-0' color="light" onClick={() => handlePrintBarcode()} disabled={rows?.length < 1 ? true : false}>
                <i className="fa fa-print"></i>
              </CustomButton>
              {/* <CustomButton className='loading-spin-hover mb-0' color="light" onClick={toggleBarcodeSetting}>
                <i className="fa fa-cog loading-spin-hover-content"></i>
              </CustomButton> */}
            </div>
          </div>)}
        TableTitle={t('testRequestPage:Sample List')}
        columns={columns}
        paging={{ page: 1, dataSize: samples?.length, size: samples?.length, totalPages: 1 }}
        data={patientDetail.id && samples}
        keyField="id"
        style={{ margin: 0, maxHeight: '220px !important' }}
        onSizePerPageChange={() => { }}
        onSelect={onSelectCheckbox}
        onSelectAll={(rows) => onSelectAllCheckbox(rows)}
        isScrollable={true}
        isSelected={true}
      />
    </div>
  )
}

const mapDispatchToProps = dispatch => ({
  onUpdateNumberOfLabel: payload => dispatch(updateNumbOfLabels(payload)),
})

export default connect(null, mapDispatchToProps)(withRouter(withTranslation(["partyPage", "message", "common", "testRequestPage", "patientVisitPage"])(BarcodeFrame)))
