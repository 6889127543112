import PropTypes from "prop-types"
import { Col, Row } from "reactstrap"
import { LabelAndValue, TitleAndInformation } from "components/Common"
import BadgeLabelAndValue from "components/Common/BadgeLabelAndValue"
import CustomSIDHover from "components/Common/Input/CustomSIDHover"
import { RESULT_STATE } from "constant"
import { convertDateFormat, convertDateFormat_VN } from "helpers/utilities"
import { withTranslation } from "react-i18next"
import { connect } from "react-redux"

const TitleAndInfo = ({
    onEdit,
    onClone,
    onCancel,
    data,
    resource,
    t,
    testsLoadingComplete,
    requestTestSamplesCount,
    requestTestTestsCount,
    ...rest
}) => {

    console.log('data123 :>> ', data);

    return (
        <TitleAndInformation
            onEdit={onEdit}
            onClone={onClone}
            onCancel={onCancel}
            resource={resource}
            {...rest}
        >
            <Row>
                <Col xs="12" md="12">
                    <Row>
                        <Col md="auto" className="pr-6">
                            <LabelAndValue
                                label={t("ID")}
                                value={data.id}
                            />
                        </Col>
                        {data.patientName && data.patientName != ' ' &&
                            <Col md="auto" className="pr-6">
                                <LabelAndValue
                                    label={t("testRequestPage:Patient Name")}
                                    value={data.patientName}
                                    className={"text-primary"}
                                    linkTo={`/Patient/${data.patientId}/view?tab=1`}
                                />
                            </Col>
                        }
                        {data.dob && data.dob != '' &&
                            <Col md="auto" className="pr-6">
                                <LabelAndValue
                                    label={t("testRequestPage:Birthday")}
                                    value={convertDateFormat_VN(data.dob)}
                                />
                            </Col>
                        }
                        {data.dob && data.dob != '' &&
                            <Col md="auto" className="pr-6">
                                <LabelAndValue
                                    label={t("testRequestPage:Age")}
                                    value={new Date().getFullYear() - new Date(data.dob).getFullYear()}
                                />
                            </Col>
                        }
                        {data.genderName && data.genderName != '' &&
                            <Col md="auto" className="pr-6">
                                <LabelAndValue
                                    label={t("testRequestPage:Gender")}
                                    value={t(data.genderName)}
                                />
                            </Col>
                        }
                        {data.patientId && data.patientId != 'undefined' &&
                            <Col md="auto" className="pr-6">
                                <LabelAndValue
                                    label={t("PID")}
                                    value={data.patientId}
                                />
                            </Col>
                        }
                        <Col md="auto" className="pr-6">
                            <LabelAndValue
                                label={t("testRequestPage:Hospital/Clinic")}
                                value={data.companyName}
                                className={"text-primary"}
                                linkTo={`/Company/${data.companyId}/view`}
                            />
                        </Col>
                        <Col md="auto" className="pr-6">
                            <p className="text-muted text-truncate mb-2 font-size-12">SID</p>
                            <CustomSIDHover data={data.sampleIds} />
                        </Col>
                        <Col md="auto" className="pr-6">
                            <LabelAndValue
                                label={t("testRequestPage:Sample Count")}
                                value={requestTestSamplesCount}
                            />
                        </Col>

                        <Col md="auto" className="pr-6">
                            {testsLoadingComplete && <LabelAndValue
                                label={t("testRequestPage:Test Count")}
                                value={requestTestTestsCount}
                            />}
                        </Col>
                        <Col md="auto" className="pr-6">
                            <LabelAndValue
                                label={t("testRequestPage:Create Method")}
                                value={data.createdMethodName}
                            />
                        </Col>

                        <Col md="auto">
                            <BadgeLabelAndValue
                                label={t("testRequestPage:State")}
                                type={RESULT_STATE[data.state]}
                                value={t(`testRequestPage:${RESULT_STATE[data.state]}`)}
                                className={"fw-500"}
                            />
                        </Col>
                        <Col md="auto" className="d-flex justify-content-end ms-auto">
                            <i className="bx bx-notepad" style={{ fontSize: '48px', color: '#adb5bd' }}></i>
                        </Col>
                    </Row>
                </Col>
            </Row>

        </TitleAndInformation>
    )
}

TitleAndInfo.propTypes = {
    onEdit: PropTypes.func,
    onClone: PropTypes.func,
    onCancel: PropTypes.func,
    data: PropTypes.object,
    t: PropTypes.any
}

const mapStateToProps = ({ testRequest }) => ({
    testsLoadingComplete: testRequest.testsLoadingComplete,
    requestTestSamplesCount: testRequest.requestTestSamplesCount,
    requestTestTestsCount: testRequest.requestTestTestsCount,
})

const mapDispatchToProps = dispatch => ({
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withTranslation(["message", "common", "testRequestPage"])(TitleAndInfo))