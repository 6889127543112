import { getI18nextLng } from "helpers/utilities"
import ReasonDeleteModal from "pages/Laboratory/PatientVisitNew/Modal/ReasonDeleteModal"
import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import { withTranslation } from "react-i18next"
import { connect } from "react-redux"
import { ChangeRequestSampleTable, ChangeRequestSampleTableSuccess, deleteTestRequestTest, editTestRequestTestSampleType, getTestRequestProfileDetail, getTestRequestSample, getTestRequestTestDetail, getTestrequestTestsDetail } from "store/laboratory/testRequest/actions"
import TestsTabTable from "./TestsTabTable"

let lang = getI18nextLng()
var dataFilterGlobal = []
const TestsTab = ({
    testRequestTests,
    onDeleteTestRequestTest,
    onEditSampleType,
    onGetTestRequestSample,
    isEdit,
    companyId,
    onChange,
    testRequestSamples,
}) => {
    const [filter, setFilter] = useState('')
    const [dataTests, setDataTests] = useState([])
    const [itemDelete, setItemDelete] = useState({})
    const [reasonModal, setReasonModal] = useState(false)

    const reasonToggle = (item) => {
        setReasonModal(prev => !prev)
        setItemDelete(item)
    }

    const onDeleteToggleHandler = (item, reason) => {
        item.reason = reason
        onDeleteTestRequestTest(item, () => {
            setReasonModal(prev => !prev)
            onGetTestRequestSample({ companyId: companyId || 1 }, (vals) => {
                onChange && onChange(vals)
               
            })
        })
    }

    useEffect(() => {
        dataFilterGlobal = []
        getDataFilterOptions(testRequestTests)
        setFilter('')
    }, [testRequestTests])

    useEffect(() => {
        if (filter && filter != '') {
            filterData(testRequestTests)
        }
        else {
            setDataTests(testRequestTests)
        }
    }, [filter])

    const filterData = (data) => {
        let ids = []
        for (let index = 0; index < data.length; index++) {
            const element = data[index];
            if (element.sampleType != filter) {
                ids.push(`${element.code}${element.id}`)
            }
            if (element.children && element.children.length > 0) {
                for (let indexParent = 0; indexParent < element.children.length; indexParent++) {
                    const parent = element.children[indexParent];
                    if (parent.sampleType != filter) {
                        ids.push(`${parent.code}${parent.id}`)
                    }
                    if (parent.children && parent.children.length > 0) {
                        for (let indexChild = 0; indexChild < parent.children.length; indexChild++) {
                            const child = parent.children[indexChild];
                            if (child.sampleType != filter) {
                                ids.push(`${child.code}${child.id}`)
                            }
                        }
                    }
                }
            }
        }
        let res = data.filter(x => ids.findIndex(z => z == `${x.code}${x.id}`) < 0);
        res.forEach(element => {
            if (element.children && element.children.length > 0) {
                element.children = element.children.filter(x => ids.findIndex(z => z == `${x.code}${x.id}`) < 0);
            }
            element.children && element.children.forEach(child => {
                if (child.children && child.children.length > 0) {
                    child.children = child.children.filter(x => ids.findIndex(z => z == `${x.code}${x.id}`) < 0);
                }
            });
        });
        setDataTests(res)
    }

    const getDataFilterOptions = (data) => {
        data.forEach(element => {
            if (element.sampleTypeName != '' && dataFilterGlobal.findIndex(x => x.value == element.sampleType) < 0)
                dataFilterGlobal.push({
                    label: `${element.sampleType} - ${element.sampleTypeName}`,
                    value: element.sampleType
                })
            if (element.children && element.children.length > 0)
                getDataFilterOptions(element.children)
        });

    }
    const onEditSampleTypeHandler = (item, sample) => {
        onEditSampleType({ item, sample })
        if (!isEdit) {
            onGetTestRequestSample({ companyId: companyId || 1 })
        }
    }

    return (
        <React.Fragment>
            <div className="TableTestRequest testsTabTable">
                <TestsTabTable
                    data={testRequestTests || []}
                    // onDelete={onDeleteToggleHandler}
                    onDelete={reasonToggle}
                    onEditSampleType={onEditSampleTypeHandler}
                    filter={filter}
                    testRequestSamples={testRequestSamples}
                />
            </div>
            <ReasonDeleteModal
                modal={reasonModal}
                toggle={() => {
                    setReasonModal(false)
                }}
                onValidSubmit={(e, value) => {
                    onDeleteToggleHandler(itemDelete, value.reason)
                }}
            />
        </React.Fragment>
    )
}

TestsTab.propTypes = {
    onRefresh: PropTypes.func,
    t: PropTypes.any,
    testRequestTests: PropTypes.array,
    onDeleteTestRequestTest: PropTypes.func,
    onEditSampleType: PropTypes.func,
    onGetProfileById: PropTypes.func,
    onGetTestRequestSample: PropTypes.func,
    onGetTestRequestTests: PropTypes.func
}

TestsTab.defaultProps = {}

const mapStateToProps = ({ testRequest }) => ({

})

const mapDispatchToProps = dispatch => ({
    onDeleteTestRequestTest: (payload, callback) => dispatch(deleteTestRequestTest(payload, callback)),
    onEditSampleType: payload => dispatch(editTestRequestTestSampleType(payload)),
    onGetProfileByCode: (payload) => dispatch(getTestRequestProfileDetail(payload)),
    onGetTestByCode: (payload) => dispatch(getTestRequestTestDetail(payload)),
    onGetTestRequestSample: (payload, callback) => dispatch(getTestRequestSample(payload, callback)),
    onGetTestRequestTests: (requestId) => dispatch(getTestrequestTestsDetail(requestId)),
    onChangeRequestSampleTableSuccess: (payload, callback) => dispatch(ChangeRequestSampleTable(payload, callback)),
    onChangeRequestSampleTable: payload =>
        dispatch(ChangeRequestSampleTableSuccess(payload)),
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withTranslation(["message", "common", "testRequestPage"])(TestsTab))
