import {
    CustomButton, CustomModal
} from "components/Common";
import { ModalBody, ModalFooter } from "reactstrap";
import CustomBootstrapInlineTable from "components/Common/CustomInlineTable/CustomBootstrapInlineTable";
import { convertDateFormat } from "helpers/utilities";
import { useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import { Link } from "react-router-dom";

const HistoryModal = ({
    modal,
    toggle,
    data,
    formEl,
    onValidSubmit,
    t,
    ...rest
}) => {
    const title = t("General Result History");
    const [dataSource, setDataSource] = useState([data]);
    // const yesterday = convertDateFormat(new Date().setDate(new Date().getDate() - 1), "YYYY-MM-DD")
    // const filteredItems = data?.filter(item => {
    // const itemDate = convertDateFormat(item.requestDate, "YYYY-MM-DD");
    // return itemDate < yesterday;
    // });
    useEffect(() => {
        setDataSource(data)
    }, [data])

    const columns = [
        {
            dataField: "id",
            text: "id",
            hidden: true,
        },
        {
            dataField: "idx",
            text: "#",
            searchable: false,
            formatter: (cellContent, item, index) => {
                return index + 1
            },
        },
        {
            dataField: "requestDate",
            text: t("common:Date"),
            formatter: (cellContent, item, index) => {
                return convertDateFormat(cellContent, 'YYYY-MM-DD')
            },
        },
        {
            dataField: "sid",
            text: t("SID"),
            formatter: (cellContent, item, index) => {
                return cellContent
            },
        },
        {
            dataField: "sampleTypeName",
            text: t("Sample Type"),
        },
        {
            dataField: "testCode",
            text: t("common:Code"),
            formatter: (cellContent, item, index) => {
                return <Link to={`/test/${item.testId}/view?tab=1`} target="_blank">{`${item.testCode}`}</Link>
            },
        },
        {
            dataField: "testName",
            text: t("Test Name"),
            formatter: (cellContent, item, index) => {
                return <Link to={`/test/${item.testId}/view?tab=1`} target="_blank">{`${item.testName}`}</Link>
            },
        },

        {
            dataField: "result",
            text: t("Result"),
            formatter: (cellContent, item, index) => {
                return cellContent
            },
        },
        {
            dataField: "resultText",
            text: t("Result Text"),
            formatter: (cellContent, item, index) => {
                return cellContent
            },
        },
    ]
    return (
        <CustomModal modal={modal} title={title} onToggle={toggle} size="lg" customClass={"model-height"} >
            <ModalBody>
                <>
                    <CustomBootstrapInlineTable
                        columns={columns}
                        data={dataSource || []}
                        isScrollable
                        isEnableLoadInfo={true}
                        searchText={""}
                        draggable
                        keyField={"id"}
                        {...rest}
                    />
                </>
            </ModalBody>
            <ModalFooter>
                <CustomButton
                    text={t("testResultPage:Cancel")}
                    type="button"
                    onClick={toggle}
                    data-dismiss="modal"
                    className="button-width"
                />
            </ModalFooter>
        </CustomModal>
    )
}

HistoryModal.propTypes = {
}

export default withTranslation(["testResultPage", "common"])(HistoryModal)