import { CustomButton, FilterButton, showErrToast, showToast } from "components/Common"
import CustomBootstrapTableAsync from "components/Common/CustomTable/CustomBootstrapTableAsync"
import { convertDateFormat, convertDateFormat_VN, GetDataUrlReportConfig, indexCalculator } from "helpers/utilities"
import { withTranslation } from "react-i18next"
import ReactTooltip from "react-tooltip"
import FilterForm from "./FilterForm"
import { ModuleIds } from "constant"
import { getFilePrint, getTestrequestGeneralDetailById, getTestrequestSamplesDetailById, LisGetRequestOrder, LisGetRequestOrderV2, pingToPrintService, sendDataPrint } from "helpers/app-backend"
import { useEffect, useState } from "react"
import { connect } from "react-redux"

const RESOURCE = ModuleIds.PatientVisit

const WaitingRequest = ({
  data,
  t,
  pagingWaiting,
  onResetHandler,
  onSubmitFilterPatient,
  onRowClickWaitingRequest,
  connectors,
  model
}) => {

  const [dataSource, setDataSource] = useState(data || [])
  const [patientVisitConfig, setPatientVisitConfig] = useState({})
  const [collector, setCollector] = useState({})
  const [hisConnector, setHisConnector] = useState([])

  const hisConnectorOptions = hisConnector.filter(connector => connector.status === true).map(_connector => {
    return { value: _connector.code, label: _connector.name }
  })


  useEffect(() => {
    setDataSource(data)
  }, [data])

  useEffect(() => {
    setHisConnector(connectors)
  }, [connectors])

  const item = localStorage.getItem("patientVisitConfig")
  const defaultCollector = localStorage.getItem("defaultCollector")
  const getPatientVisitConfig = () => {
    if (item) {
      try {
        setPatientVisitConfig(JSON.parse(item))
      } catch (error) {

      }
    }
    if (defaultCollector) {
      try {
        setCollector(JSON.parse(defaultCollector))
      } catch (error) {

      }
    }
  }
  useEffect(() => {
    getPatientVisitConfig()
    window.addEventListener('storage', getPatientVisitConfig)
    return () => {
      window.removeEventListener('storage', getPatientVisitConfig)
    }
  }, [item])

  const columns = [
    {
      dataField: "id",
      text: "#",
      style: { textAlign: 'center' },
      headerStyle: { textAlign: 'center' },
      formatter: (cellContent, item, index) => {
        if (item.id == -1) {
          return <span id={`watch_end_of_document1`}></span>
        }
        else
          return indexCalculator(0, 0, index + 1)
      },
    },
    {
      dataField: "requestDate",
      text: t("patientVisitPage:Date"),
      formatter: (cellContent, item, index) => {
        return convertDateFormat(item.gioChiDinh, "DD-MM-YYYY")
      },
    },
    {
      dataField: "objectName",
      text: t("common:ServiceType"),
      formatter: (cellContent, item, index) => {
        return cellContent
      },
    },
    // {
    //   dataField: "",
    //   text: "",
    //   formatter: (cellContent, item, index) => {
    //     return (
    //       <div className="d-flex">
    //         {item?.groupCode && <i
    //           key={index}
    //           data-tip
    //           data-for={`groupCode-${index}`}
    //           className="mdi mdi-bookmark"
    //           style={{
    //             fontSize: 'larger',
    //             color: 'purple'
    //           }}
    //         >
    //           {item.organizationName && <ReactTooltip id={`groupCode-${index}`} place="top" effect="solid">
    //             {item.organizationName || ''}
    //           </ReactTooltip>}
    //         </i>}
    //       </div>
    //     )
    //   }
    // },
    {
      dataField: "patientName",
      text: t("Name"),
      formatter: (cellContent, item, index) => {
        return (
          <div style={{ color: "#3c3ce4", paddingTop: '2px' }}>{item.hoTen}</div>
        )
      }
    },
    {
      dataField: "patientId",
      text: t("PID"),
      formatter: (cellContent, item, index) => {
        return item.patientId
      }
    },
    {
      dataField: "age",
      text: t("DOB"),
      formatter: (cellContent, item, index) => item.ngaySinh && <div>{convertDateFormat_VN(item.ngaySinh, "DD-MM-YYYY")}</div>
    },
    {
      dataField: "update",
      text: t(""),
      formatter: (cellContent, item, index) => {
        let id = `${item.maYTe}${index}`;
        return <div onClick={(e) => {
          e.preventDefault()
          GetRequest(item.maYTe, '', id)
        }}>
          <i id={id} className="bx bx-right-arrow-circle font-size-22" style={{ color: "#3c3ce4" }} />
        </div>
      }
    }
  ]

  const GetRequest = async (keySearch, sid, id) => {
    let itemEle = document.getElementById(id)
    if (itemEle && itemEle.className == "fas fa-sync-alt loading-spin") {
      return;
    }
    try {
      if (itemEle) itemEle.className = "fas fa-sync-alt loading-spin";
      const req = await LisGetRequestOrderV2(keySearch, patientVisitConfig?.requestDate ? patientVisitConfig?.requestDate : moment().format("YYYY-MM-DD")
        , hisConnectorOptions.length < 2 ? hisConnectorOptions[0]?.value : patientVisitConfig?.connector ? patientVisitConfig?.connector : "", sid
        , patientVisitConfig.autoCollected || false, collector.collectorId || '')
      let samplesInfo = {}
      let generalInfo = {}

      if (req && req.ids.length > 0) {
        //Tiếp nhận thành công mã hồ sơ
        showToast(
          `${t("message:ReceiveFileSuccess", {
            field: `${keySearch}`,
          })}`
        )
        onResetHandler('1')
        // let item = {}

        // if (item.id) {
        //   onSelectPatient("", item)
        //   const res = await getTestrequestSamplesDetailById(item.id)
        //   const general = await getTestrequestGeneralDetailById(item.id)

        //   if (res !== null && res?.samples) {
        //     samplesInfo = res.samples
        //     generalInfo = general
        //   }
        // }
      }
      else {
        showErrToast(
          `${t("message:AlternateIdNotFound", {
            field: `${keySearch}`,
          })}`
        )
      }
      if (itemEle) itemEle.className = "bx bx-right-arrow-circle font-size-22";
      // if (req && req.id != 0 && patientVisitConfig.autoPrintBarcode == true) {
      //   PrintConfig(samplesInfo, generalInfo)
      // }

    } catch (error) {
      if (itemEle) itemEle.className = "bx bx-right-arrow-circle font-size-22";
    }
  }
  const PrintConfig = async (samplesInfo, generalInfo) => {

    const print = JSON.parse(localStorage.getItem("printConfig"))
    let printColumns = Number(print.columns)
    let printPage = ['B1', 'B2', 'B3']

    let sampleListType = samplesInfo?.map((obj, index) => ({ ...obj, id: `${index + 1}-${obj?.sid}` }))
    let SID = sampleListType?.map((_slt) => _slt?.sid?.toString())
    let subSID = sampleListType?.map((_slt) => _slt?.subSID)
    let sampleType = sampleListType?.map((_slt) => _slt?.sampleType?.toString())

    const formattedSampleType = sampleType?.map(item => `'${item}'`)

    if (reportResultInfos.length > 0 && !isEmpty(reportResultInfos[0].uri)) {
      const res = await getFilePrint({
        ...GetDataUrlReportConfig(reportResultInfos, ReportResource.RequestId, ""),
        SID: SID?.length > 1 ? `[${SID}]` : SID[0],
        SubSID: subSID?.length > 1 ? `[${subSID}]` : subSID[0],
        RequestDate: generalInfo.requestDate,
        SampleType: formattedSampleType?.length > 1 ? `[${formattedSampleType}]` : formattedSampleType[0].replace(/['"]/g, ""),
        Page: printPage[printColumns - 1]
      })
      try {
        const getPingPrintService = await pingToPrintService();
        if (getPingPrintService.pingTime) {
          const dataSendPrint = await sendDataPrint({
            ...GetDataUrlReportConfig(reportResultInfos, ReportResource.RequestId, ""),
            "filePath": `${res.reportUrl}`,
          })
        } else {
          window.open(process.env.REACT_APP_BASE_ENDPOINT + res?.reportUrl)
        }
      }
      catch {
        window.open(process.env.REACT_APP_BASE_ENDPOINT + res?.reportUrl)
      }
    }
    else {
      console.log('failed')
    }
  }
  const getDataDisplay = () => {
    let res = [...data]
    if (res.length > 0) {
      res.push({ id: -1, testCode: '' })
    }
    countTotal = res.length
    return res
  }

  return (
    <div className={`table-patient-visit watting-request-content-6 ${data.length > 0 ? 'table-expand-custom' : ''}  `} style={{ height: '100%' }}>
      <CustomBootstrapTableAsync
        TableTitle={t('Patient Waiting List')}
        columns={columns}
        data={dataSource}
        keyField="id"
        onSizePerPageChange={() => { }}
        showSelectRow={false}
        paging={pagingWaiting}
        isScrollable={true}
        style={{ height: '270px' }}
        resource={RESOURCE}
        onRowClick={onRowClickWaitingRequest}
        onRefresh={() => { onResetHandler('1') }}

      // customButtonRight={() => (
      //   <>
      //     <FilterButton
      //       onReset={onResetHandler}
      //       onSubmit={onSubmitFilterPatient}
      //       direction={'end'}
      //       className={''}
      //     >
      //       <FilterForm model={model} />
      //     </FilterButton>
      //     {/* <i
      //       className="font-size-24 bx bx-right-arrow-circle p-0 btn btn-link"
      //       style={{ color: "#3c3ce4" }}
      //     /> */}
      //   </>

      // )}
      />
    </div>
  )
}
//export default withTranslation(["testRequestPage", "message", "patientVisitPage"])(WaitingRequest)

const mapStateToProps = ({ hisConnector, testRequest, generalSetting }) => ({
  connectors: hisConnector.hisConnectors || [],
  hisConnector: hisConnector.hisConnector || {},
  samples: testRequest.testRequest.samples,
  generalSettingObject: generalSetting.generalSettingObject
})

const mapDispatchToProps = dispatch => ({

})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation(["testRequestPage", "message", "patientVisitPage"])(WaitingRequest))

