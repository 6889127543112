import PropTypes from "prop-types"
import React, { useEffect, useRef, useState } from "react"
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"
import TestResultTable from "./TestResultTable"


import {
    deleteResults, getResults, getResultsWithoutLoading, getTestResultDetail, resetSearchQuery
} from "store/laboratory/testResult/actions"

import { ModuleIds } from "constant"
import { selectCheckboxHandler } from "helpers/utilities"
import { withTranslation } from "react-i18next"
import { addNewFileResult } from "store/actions"
import HeaderButtons from "./HeaderButtons"

const RESOURCE = ModuleIds.TestResult
let task = null
let isLoadFinished = true;
const TestResult = ({
    paging,
    onGetResults,
    onResetSearchQuery,
    testResults,
    loadingResults,
    updateResultsTime,
    onChangeResult,
    t,
    testResult,
    onGetResultsWithoutLoading,
    onGeneralSetting,
    onGetTestResultDetail
}) => {
    const ref = useRef();
    const [modelFilter, setModelFilter] = useState({});
    const [row, setRow] = useState(false)
    const [rows, setRows] = useState([])

    useEffect(() => {
        const interval = setInterval(() => {
            onGetResultListWithoutLoading()
        }, 10000);
        return () => clearInterval(interval);
    }, []);
    
    useEffect(() => {
        onResetSearchQuery()
    }, [])

    useEffect(() => {
        if(testResults?.length === 1) {
            setRow(testResults[0]);
            onGetTestResultDetail(testResults[0]?.requestId)
        }
    }, [testResults])

    const onGetResultList = (payload) => {
        onGetResults(payload)
    }

    const onGetResultListWithoutLoading = () => {
        if (isLoadFinished == false)
            return
        isLoadFinished = false
        let dataFilter = ref.current?.GetDataFilter() || {}
        onGetResultsWithoutLoading({ sort: "createdDate:desc", ...dataFilter, ...modelFilter }, () => {
            isLoadFinished = true
        })

    }

    const fetchResults = () => {
        let dataFilter = ref.current?.GetDataFilter() || {}
        onGetResultList({ page: 1, sort: "createdDate:desc", ...dataFilter, ...modelFilter })
    }
    const onRefreshHandler = () => {
        isLoadFinished = true;
        fetchResults();
    }

    const onSizePerPageChange = size => {
        isLoadFinished = true;
        onGetResultList({ page: 1, size })
    }

    const onPageChange = page => {
        isLoadFinished = true;
        onGetResultList({ page })
    }

    const onSubmitFilter = values => {
        setModelFilter({ ...values })
        let dataFilter = ref.current?.GetDataFilter() || {}
        if (values.state == 0 || values.state == 1) {
            values.state = undefined
        } else {
            values.printed = undefined
        }
        onGetResultList({ page: 1, sort: "createdDate:desc", ...values, ...dataFilter })
    }

    const onSubmitFilterFromButtonField = values => {
        let dataFilter = modelFilter
        if (values.state == 0 || values.state == 1) {
            values.state = undefined
        } else {
            values.printed = undefined
        }
        onGetResultList({ page: 1, sort: "createdDate:desc", ...values, ...dataFilter })
    }

    const onSelectCheckbox = (row, isSelected) => {
        const { rowsState, currentRow } = selectCheckboxHandler(
            rows,
            row,
            isSelected
        )
        setRows(rowsState)
        setRow(currentRow)
    }

    const onSelectAllCheckbox = rows => {
        setRows(rows)
        if (rows.length < 1) setRow({})
        else setRow(rows[rows.length - 1])
    }

    return (
        <React.Fragment>
            <HeaderButtons ref={ref}
                onSubmit={(data) => {
                    onSubmitFilterFromButtonField(data)
                }}
            />
            <TestResultTable
                onGeneralSetting={onGeneralSetting}
                testResult={testResult}
                testResults={testResults}
                onRefresh={onRefreshHandler}
                onPageChange={onPageChange}
                paging={paging}
                onSizePerPageChange={onSizePerPageChange}
                onSubmitFilter={onSubmitFilter}
                loading={loadingResults}
                updatedTime={updateResultsTime}
                onChangeResult={onChangeResult}
                onSelect={onSelectCheckbox}
                onSelectAll={onSelectAllCheckbox}
            />
        </React.Fragment>
    )
}

TestResult.propTypes = {
    paging: PropTypes.object,
    onGetResults: PropTypes.func,
    onResetSearchQuery: PropTypes.func,
    t: PropTypes.any,
    loadingResults: PropTypes.bool,
    updateResultsTime: PropTypes.object,
    onGetTestResultDetail: PropTypes.func,
}

const mapStateToProps = ({ testResult }) => ({
    testResults: testResult.testResults,
    paging: testResult.paging,
    loadingResults: testResult.loadingResults,
    updateResultsTime: testResult.updateResultsTime,
    testResult: testResult.testResult,
})

const mapDispatchToProps = dispatch => ({
    onGetResults: payload => dispatch(getResults(payload)),
    onGetResultsWithoutLoading: (payload, callback) => dispatch(getResultsWithoutLoading(payload, callback)),
    onResetSearchQuery: () => dispatch(resetSearchQuery()),
    onGetTestResultDetail: id => dispatch(getTestResultDetail(id)),
    onDeleteResults: ({ results, callback }) => dispatch(deleteResults({ results, callback })),
    onAddNewFile: (data, callback) =>
        dispatch(addNewFileResult(data, callback)),
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(withTranslation(["testResultPage", "message", "common"])(TestResult)))