import sidebar from "./sidebar.json"
import common from "./common.json"
import message from "./message.json"
import moment from "./moment.json"
import table from "./table.json"
import button from "./button.json"

import parameterPage from "./parameterPage.json"
import companyPage from "./companyPage.json"
import departmentPage from "./departmentPage.json"
import rolePage from "./rolePage.json"
import userPage from "./userPage.json"
import profilePage from "./profilePage.json"
import partyPage from "./partyPage.json"
import testPage from "./testPage.json"
import testProfilePage from "./testProfilePage.json"
import interfacePage from "./interfacePage.json"
import hisConnectorPage from "./hisConnectorPage.json"
import machinePage from "./machinePage.json"
import reportPage from "./reportPage.json"
import accessionNumberPage from "./accessionNumberPage.json"
import testConfig from "./testConfig.json"
import deliveryPage from "./deliveryPage.json"
import testResultPage from "./testResultPage.json"
import resultPage from "./resultPage.json"
import testRequestPage from "./testRequestPage.json"
import processPage from "./processPage.json"
import workListPage from "./workListPage.json"
import workOrderPage from "./workOrderPage.json"
import generalSetting from "./generalSetting.json"
import notificationPage from "./notificationPage.json"
import loginHistoryPage from "./loginHistoryPage.json"
import insurancePage from "./insurancePage.json"
import patientVisitPage from "./patientVisitPage.json"
import qcResultPage from "./qcResultPage.json"
import patientGroup from "./patientGroup.json"
import personalSettingPage from './personalSettingPage.json'
import printConfigPage from './printConfigPage.json'

const translations = {
  sidebar,
  common,
  parameterPage,
  companyPage,
  departmentPage,
  rolePage,
  userPage,
  message,
  moment,
  table,
  button,
  profilePage,
  partyPage,
  testPage,
  testProfilePage,
  interfacePage,
  machinePage,
  testConfig,
  hisConnectorPage,
  reportPage,
  accessionNumberPage,
  deliveryPage,
  testResultPage,
  resultPage,
  testRequestPage,
  processPage,
  workListPage,
  workOrderPage,
  generalSetting,
  notificationPage,
  loginHistoryPage,
  insurancePage,
  patientVisitPage,
  qcResultPage,
  patientGroup,
  personalSettingPage,
  printConfigPage
}

export default translations
