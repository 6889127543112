import { ConfirmModal, ConfirmModal2, CustomNav, CustomNavLink, CustomSplitPane, SystemInformation, showToast } from "components/Common"
import ModalConfigPrinter from "components/Common/Modals/ModalConfigPrinter"
import ModalPreview from "components/Common/Modals/ModalPreview"
import { ExportExtension, ModuleIds, ReportAction, ReportResource } from "constant"
import { downloadFileWithName } from "helpers/api_helper2"
import { confirmValidPrint, exportFileResult, getFilePrintResult, pingToPrintService } from "helpers/app-backend"
import { GetDataUrlReportConfig, GetResourceReportConfig, GetResourceReportIdConfig, NameToSlug, getUrlParamByKey, insertUrlParam } from "helpers/utilities"
import { isEmpty } from "lodash"
import PropTypes from "prop-types"
import React, { useEffect, useRef, useState } from "react"
import { withTranslation } from "react-i18next"
import { connect, useDispatch, useSelector } from "react-redux"
import { Link, withRouter } from "react-router-dom"
import { Card, CardBody, Col, Row, TabContent, TabPane } from "reactstrap"
import { addNewFileResult } from "store/actions"
import {
    ApprovedValidAndPublishResult,
    SignTestResultResults,
    UnsignTestResultResults,
    clearTestResultDetail,
    getTestResultDetail, getTestResultResults, setLoadingChangeDetail
} from "store/laboratory/testResult/actions"
import LeftFrame from "./LeftFrame"
import SummaryInfo from "./SummaryInfo"
import TabHistory from "./TabHistory"
import TabResult from "./TabResult"
import TitleAndInfo from "./TitleAndInfo"

const RESOURCE = ModuleIds.TestResult

const CardDetail = ({
    id,
    history,
    testResult = {},
    t,
    onGetTestResultDetail,
    onAddNewFileResult,
    onRefresh,
    onSetLoadingChangeDetail,
    loadingDetail,
    sticky,
    match,
    onApprovedValidAndPublishResult,
    onSignTestResultResults,
    onUnsignTestResultResults,
    isFastValidate,
    userValidate
}) => {
    const [idResult, setIdResult] = useState(match.params.id)
    const [isClone, setIsClone] = useState(false)
    const [modal, setModal] = useState(false)
    const formEl = useRef(null)
    const formTable = useRef(null)
    const [currentTab, setCurrentTab] = useState(getUrlParamByKey('tab') || '1')
    const [actionState, setActionState] = useState('');
    const [open, setOpen] = useState(false)
    const [modalPreview, setModalPreview] = useState(false);
    const [testResultChange, setTestResultChange] = useState([])
    const [isSpin, setIsSpin] = useState(false)
    const [generalSetting, setGeneralSetting] = useState([])
    const [confirmSendResultModal, setConfirmSendResultModal] = useState(false)
    const isIntegration = generalSetting && generalSetting?.find(item => item.fieldName === 'IntegratedApproval')?.fieldValue === 'Integration'
    const item = localStorage.getItem("printResultConfig")
    const [printResultConfig, setPrintResultConfig] = useState({})
    const [reportResult, setReportResult] = useState({})

    const getPrintResultConfig = () => {
        if (item) {
            try {
                setPrintResultConfig(JSON.parse(item))
            } catch (error) {
                console.log('error', error)
            }
        }
    }

    useEffect(() => {
        getPrintResultConfig()
        window.addEventListener('storage', getPrintResultConfig)
        return () => {
            window.removeEventListener('storage', getPrintResultConfig)
        }
    }, [item])

    const dispatch = useDispatch()
    useEffect(() => {
        setCurrentTab(`${getUrlParamByKey('tab')}`)
        return () => {
            //xóa result cũ
            dispatch(clearTestResultDetail())
        }
    }, [])

    useEffect(() => {
        if (getUrlParamByKey('tab') != currentTab) {
            insertUrlParam({ tab: currentTab })
        }
    }, [currentTab])
    useEffect(() => {
        setCurrentTab(getUrlParamByKey('tab'))
    }, [window.location.search])
    const [rowEdit, setRowEdit] = useState({})
    const [confirmModal, setConfirmModal] = useState(false);
    const toggleModal = () => {
        setModal(prev => !prev)
    }

    const { reportInfos } = useSelector(state => ({
        reportInfos: state.Authorization.reportinfo || [],
    }))

    useEffect(() => {
        if (printResultConfig.printReportEng) {
            setReportResult([reportInfos[1]])
        } else {
            setReportResult([reportInfos[0]])
        }
    }, [reportInfos, printResultConfig])

    useEffect(() => {
        if (loadingDetail == true) {
            setIsSpin(true)
        }
        else {
            setIsSpin(false)
        }
    }, [loadingDetail])

    useEffect(() => {
        if (idResult) {
            setIsSpin(true)
            setCurrentTab('1');
            onGetTestResultDetail({
                id: idResult, callback: () => {
                }
            })
        }
    }, [idResult])

    useEffect(() => {
        setModal(false)
    }, [testResult.id])

    useEffect(() => {
        if (testResult.id)
            onRefresh(testResult.id)
    }, [testResult.fileId])


    const onEditHandler = () => {
        setIsClone(false)
        toggleModal()
    }

    const onCloneHandler = () => {
        setIsClone(true)
        toggleModal()
    }

    const toggleParamModal = () => {
        setModal(prev => !prev)
    }

    useEffect(() => {
        setRowEdit(testResult)
    }, [testResult])

    const onDelete = () => {
        setConfirmModal(true)
    }

    const onDeleteToggleHandler = (e, param) => {
        setConfirmModal(false)
    }

    const onDeleteRequestsHandler = () => {
        onDeleteRequests({ requests: [testResult], callback: callback() })
    }

    const callback = () => {
        const url = `/TestResult`
        setConfirmModal(false)
        history.replace(url)
    }

    const PrintConfig = async () => {
        if (reportResult.length > 0 && !isEmpty(reportResult[0].uri)) {
            let res
            if (!isFastValidate) {
                res = await getFilePrintResult({
                    ...GetDataUrlReportConfig(reportResult, ReportResource.TestResult, testResult.fileId),
                    ResultId: `${testResult.id}`,
                    testIds: formTable.current.GetIsPrintIds()?.length > 0 ? formTable.current.GetIsPrintIds() : formTable.current.GetAllIdsHasResult(),
                    page: formTable.current.GetReportType()?.length > 0 ? formTable.current.GetReportType() : formTable.current.GetAllReportType(),
                    SampleTypes: formTable.current.GetSampleType()?.length > 0 ? formTable.current.GetSampleType() : formTable.current.GetAllSampleType(),
                    ValidatedBy: userValidate
                })
            } else {
                const id = testResult.id
                const params = {
                    Module: ModuleIds.TestResult,
                    ItemAction: ReportAction.Create,
                    ItemValue: `${id}`,
                    reportParameters: {
                        ...GetDataUrlReportConfig(reportResult, ReportResource.TestResult, testResult.fileId),
                        ResultId: `${testResult.id}`,
                    },
                    orderCode: testResult.alternateId,
                    medicalId: testResult.medicalId,
                    patientName: NameToSlug(testResult.patientName)
                }
                res = await confirmValidPrint({
                    ...GetDataUrlReportConfig(reportInfos, ReportResource.TestResult, testResult.fileId),
                    ResultId: `${testResult.id}`,
                    testIds: formTable.current.GetIsPrintIds()?.length > 0 ? formTable.current.GetIsPrintIds() : formTable.current.GetAllIdsHasResult(),
                    page: formTable.current.GetReportType()?.length > 0 ? formTable.current.GetReportType() : formTable.current.GetAllReportType(),
                    SampleTypes: formTable.current.GetSampleType()?.length > 0 ? formTable.current.GetSampleType() : formTable.current.GetAllSampleType(),
                    ValidatedBy: userValidate
                }, params)
            }
            try {
                const getPingPrintService = await pingToPrintService();
                if (getPingPrintService.pingTime) {
                    const dataSendPrint = await sendDataPrint({
                        ...GetDataUrlReportConfig(reportResult, ReportResource.TestResult, testResult.fileId),
                        "filePath": `${res.reportUrl}`,
                        // "quantity": rowState.numberOfLabels
                    })
                } else {
                    window.open(process.env.REACT_APP_BASE_ENDPOINT + res?.reportUrl)
                }
            }
            catch {
                window.open(process.env.REACT_APP_BASE_ENDPOINT + res?.reportUrl)
            }
            // let url = process.env.REACT_APP_BASE_ENDPOINT + res?.reportUrl
            // let fileName = `${testResult.alternateId}_${NameToSlug(testResult.patientName)}_${testResult.medicalId}.${ExportExtension.pdf}`;
            // let htmlString = `
            //             <html>

            // <head>
            //         <title>${fileName}</title>
            // </head>

            // <body>
            //         <iframe src="${url}"
            //                 style="position:fixed; top:0; left:0; bottom:0; right:0; width:100%; height:100%; border:none; margin:0; padding:0; overflow:hidden; z-index:999999;">
            //                 Your browser doesn't support iframes
            //         </iframe>
            // </body>

            // </html>
            //             `
            // var wnd = window.open('', "");
            // wnd.document.write(htmlString);
            // wnd.document.close()
        }
        else {
            const print = localStorage.getItem("printConfig")
            if (isEmpty(print)) {
                setOpen(true)
            }
            else {
                setModalPreview(true);
            }
        }
    }

    const onPrintHandler = (state) => {
        setActionState(state)
        PrintConfig()
    }
    const onPreviewHandler = (state) => {
        setActionState(state)
        setModalPreview(true);

    }
    const onExportHandler = async (state) => {
        setActionState(state)
        const id = testResult.id
        let page = formTable.current.GetReportType()?.length > 0 ? formTable.current.GetReportType() : formTable.current.GetAllReportType()
        let pageFormat = page.join(",")
        const params = {
            Module: ModuleIds.TestResult,
            ItemAction: ReportAction.Export,
            ItemValue: `${id}`,
            reportParameters: {
                ...GetDataUrlReportConfig(reportResult, ReportResource.TestResult, testResult.fileId),
                "ResultId": `${id}`,
                "exportFormat": ExportExtension.pdf,
                Page: pageFormat,
                hasSignature: "false"
            },
            orderCode: testResult.alternateId,
            medicalId: testResult.medicalId,
            patientName: NameToSlug(testResult.patientName)
        }
        const data = {
            ResultId: id,
            reportId: GetResourceReportIdConfig(reportResult),
            testIds: formTable.current.GetIsPrintIds()?.length > 0 ? formTable.current.GetIsPrintIds() : formTable.current.GetAllIdsHasResult(),
        }
        const res = await exportFileResult(data, params)
        if (res.length > 0) {
            res.forEach(async element => {
                await downloadFileWithName(element)
            });
        }

    }

    const onReportSettingHandler = (state) => {
        setOpen(true);
    }

    const onCreateHandler = (state) => {
        setActionState(state)
        if (isIntegration) {
            setConfirmSendResultModal(true)
        } else {
            onCreateReport(testResult.id)
        }
    }
    const onCreateReport = async (id) => {
        const params = {
            Module: ModuleIds.TestResult,
            ItemAction: ReportAction.Create,
            ItemValue: `${id}`,
            reportParameters: {
                ...GetDataUrlReportConfig(reportResult, ReportResource.TestResult, testResult.fileId),
                ResultId: `${testResult.id}`,
                hasSignature: "false",
                SampleTypes: formTable.current.GetAllSampleTypeHasResult() || [],
            },
            orderCode: testResult.alternateId,
            medicalId: testResult.medicalId,
            patientName: NameToSlug(testResult.patientName)
        }
        const data = {
            ResultId: id,
            reportId: GetResourceReportIdConfig(reportResult),
            testIds: formTable.current.GetIsPrintIds()?.length > 0 ? formTable.current.GetIsPrintIds() : formTable.current.GetAllIdsHasResult(),
        }

        if (isIntegration) {
            onApprovedValidAndPublishResult({
                data: {
                    data, params
                }, callback: (id) => {
                    showToast(
                        `${t("message:SendingReportSucceeded")}`, true
                    )
                    onGetTestResultDetail(testResult.requestId)
                    setConfirmSendResultModal(prev => !prev)
                }
            })
        } else {
            onAddNewFileResult({
                data: {
                    data, params
                }, callback: () => {
                    onGetTestResultDetail(testResult.requestId)
                }
            })
        }
    }

    const onSign = async (state) => {
        setActionState(state)
        const id = testResult.id
        let page = formTable.current.GetReportType()?.length > 0 ? formTable.current.GetReportType() : formTable.current.GetAllReportType()
        let pageFormat = page.join(",")
        const params = {
            Module: ModuleIds.TestResult,
            ItemAction: ReportAction.Export,
            ItemValue: `${id}`,
            reportParameters: {
                ...GetDataUrlReportConfig(reportResult, ReportResource.TestResult, testResult.fileId),
                "ResultId": `${id}`,
                "exportFormat": ExportExtension.pdf,
                Page: pageFormat,
            },
            orderCode: testResult.alternateId,
            medicalId: testResult.medicalId,
            patientName: NameToSlug(testResult.patientName)
        }
        const data = {
            id: id,
            reportId: GetResourceReportIdConfig(reportResult),
            testResultIds: formTable.current.GetIsValidIds() || [],
            request: params || {}
        }
        onSignTestResultResults(data, () => {
            formTable?.current?.AfterUpdate()
        })

    }

    const onUnsign = async (state) => {
        setActionState(state)
        const id = testResult.id
        let page = formTable.current.GetReportType()?.length > 0 ? formTable.current.GetReportType() : formTable.current.GetAllReportType()
        let pageFormat = page.join(",")
        const params = {
            Module: ModuleIds.TestResult,
            ItemAction: ReportAction.Export,
            ItemValue: `${id}`,
            reportParameters: {
                ...GetDataUrlReportConfig(reportResult, ReportResource.TestResult, testResult.fileId),
                "ResultId": `${id}`,
                "exportFormat": ExportExtension.pdf,
                Page: pageFormat,
            },
            orderCode: testResult.alternateId,
            medicalId: testResult.medicalId,
            patientName: NameToSlug(testResult.patientName)
        }
        const data = {
            id: id,
            reportId: GetResourceReportIdConfig(reportResult),
            testResultIds: formTable.current.GetIsValidIds() || [],
            request: params || {}
        }
        onUnsignTestResultResults(data, () => {
            formTable?.current?.AfterUpdate()
        })

    }

    const refHeader = useRef(null)
    // if (isEmpty(testResult)) return null;


    return (
        <React.Fragment>
            {/* <ModalConfigPrinter open={open} onClose={(state) => {

                setOpen(false)
            }}
                stateAction={actionState}
                resource={GetResourceReportConfig(reportInfos)}
                onPreviewClick={(state) => { onPreviewHandler(state) }}
                onPrintClick={(state) => { onPrintHandler(state) }}
                onExportClick={(state) => { onExportHandler(state) }}
            /> */}
            {modalPreview &&
                <ModalPreview
                    modal={modalPreview}
                    toggle={() => { setModalPreview(false) }}
                    fileId={testResult.fileId}
                    reportId={GetResourceReportIdConfig(reportResult)}
                    resourceReport={ReportResource.TestResult}
                    dataUrl={
                        {
                            ...GetDataUrlReportConfig(reportResult, ReportResource.TestResult, testResult.fileId),
                            "ResultId": `${testResult.id}`,
                            testIds: formTable.current.GetIsPrintIds()?.length > 0 ? formTable.current.GetIsPrintIds() : formTable.current.GetAllIds(),
                            page: formTable.current.GetReportType()?.length > 0 ? formTable.current.GetReportType() : formTable.current.GetAllReportType(),
                            SampleTypes: formTable.current.GetSampleType()?.length > 0 ? formTable.current.GetSampleType() : formTable.current.GetAllSampleType(),
                            ValidatedBy: userValidate
                        }}
                />
            }
            <div id="result-header-detail" className="" ref={refHeader}>
                <Row>
                    <Col lg="12" className="px-0">
                        {testResult &&
                            <div className={sticky ? 'header-result-detail' : 'sticky-header-detail-expand header-result-detail'}>
                                <TitleAndInfo
                                    generalSetting={generalSetting}
                                    onEdit={onEditHandler}
                                    onClone={onCloneHandler}
                                    data={testResult}
                                    title={t("Test Result")}
                                    subtitle={<>
                                        {testResult.sid ?
                                            <Link to={`/TestRequest/${testResult.requestId}/view?tab=1`} target="_blank">{`${testResult.sid}`}</Link>
                                            :
                                            <span>{t("Result")}</span>
                                        }
                                    </>
                                    }
                                    resource={RESOURCE}
                                    onDelete={onDelete}
                                    resourceReport={GetResourceReportConfig(reportResult)}
                                    onCreateClick={(state) => { onCreateHandler(state) }}
                                    onPrintClick={(state) => { onPrintHandler(state) }}
                                    onReportSettingClick={(state) => { onReportSettingHandler(state) }}
                                    onPreviewClick={(state) => { onPreviewHandler(state) }}
                                    onExportClick={(state) => { onExportHandler(state) }}
                                    // onExportClick={(state) => { onExportHandler(state) }}
                                    testResults={testResultChange || []}
                                />
                            </div >}
                    </Col >
                </Row >
            </div >
            <div className="row" style={{ height: "calc(100% - 60px)", paddingTop: 5 }}>
                <CustomSplitPane
                    LeftFrame={() => (
                        <div className="h-100 header-fixed" style={{ overflow: 'hidden', display: 'flex', flexDirection: 'column' }}>
                            <LeftFrame
                                onGeneralSetting={(val) => {
                                    setGeneralSetting(val)
                                }}
                                onChangeResult={newId => {
                                    if (idResult != newId)
                                        setIdResult(newId)
                                }}
                            />
                            <div className="result-detail-bottom-column">&nbsp;</div>
                        </div>
                    )}
                    RightFrame={() => (
                        <>
                            <div style={{ height: '100%', overflow: 'hidden', display: 'flex', flexDirection: 'column' }}>
                                <div className="header-result-detail sumamry-info-result-detail">
                                    <SummaryInfo
                                        data={testResult}
                                        createdByName={testResult.createdByName}
                                        updatedByName={testResult.updatedByName}
                                        generalSetting={generalSetting}
                                    />
                                </div>
                                <Row className="flex-1" style={{ overflow: 'hidden' }}>
                                    {isSpin &&
                                        <div className=" w-100" style={{
                                            height: '93%',
                                            position: 'absolute', zIndex: 9,
                                            background: 'rgba(256,256,256,0.5)'
                                        }}>
                                            <div className="w-100 " style={{ height: '93%' }}>
                                                <div className="text-center" style={{ marginTop: '50px' }}>
                                                    <div className="spinner-border text-primary" role="status">
                                                        <span className="sr-only">Loading...</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    }
                                    <Col lg="12" className="h-100">
                                        <Card className="h-100">
                                            <CardBody className="h-100 pb-0" style={{ padding: '10px' }}>
                                                <div className="h-100" style={{ position: 'relative' }}>
                                                    <div className="d-none">
                                                        <SystemInformation t={t} data={rowEdit} />
                                                    </div>
                                                    <Row className="h-100">
                                                        <CustomNav
                                                            onToggle={e => {
                                                                if (e == "1") {
                                                                    setTimeout(() => {
                                                                        setCurrentTab(e);
                                                                        formTable.current?.ReSetColumns()
                                                                    }, 400);
                                                                }
                                                                else {
                                                                    setCurrentTab(e);
                                                                }
                                                                // setCurrentTab(e);
                                                            }}
                                                            defaultTab={currentTab}
                                                            tabs
                                                            className="nav-tabs-custom"
                                                            //onToggle={onGetTestRequestSample}
                                                            tabContents={customActiveTab => (
                                                                <TabContent
                                                                    activeTab={customActiveTab}
                                                                    className="p-3 text-muted pb-0"
                                                                    style={{ height: "calc(100% - 41px)" }}
                                                                >
                                                                    <TabPane tabId="1" className="h-100">
                                                                        {testResult.id && currentTab == "1" &&
                                                                            <TabResult className="testsTab"
                                                                                isEdit={!isClone}
                                                                                // onValidSubmit={onValidSubmitTestsTab}
                                                                                data={[]}
                                                                                id={testResult.id}
                                                                                requestId={testResult.requestId}
                                                                                onRefreshDetail={() => { }}
                                                                                onChange={(values) => {
                                                                                    setTestResultChange(values)
                                                                                }}
                                                                                currentTab={currentTab}
                                                                                generalSetting={generalSetting}
                                                                                ref={formTable}
                                                                                onSign={(state) => { onSign(state) }}
                                                                                onUnsign={(state) => { onUnsign(state) }}
                                                                            />}
                                                                    </TabPane>
                                                                    <TabPane tabId="2" className="h-100">
                                                                        {(testResult.id) && currentTab == "2" &&
                                                                            <div className=" h-100 tab-history-detail-result">
                                                                                <TabHistory className="testsTab"
                                                                                    id={testResult.id}
                                                                                    currentTab={currentTab}
                                                                                />
                                                                            </div>}
                                                                    </TabPane>
                                                                </TabContent>
                                                            )}
                                                        >
                                                            {ListTabName.map(row => <CustomNavLink key={row.id} tabId={row.id.toString()}>
                                                                <span className="">{t(`${row.name}`)}</span>
                                                            </CustomNavLink>)
                                                            }
                                                        </CustomNav>
                                                    </Row>
                                                </div>
                                            </CardBody>
                                        </Card>
                                    </Col>
                                </Row>
                                <div className="result-detail-bottom-column">&nbsp;</div>
                            </div>
                        </>
                    )}
                    InitialSize={70}
                />
            </div>
            <ConfirmModal
                modal={confirmModal}
                title={`${t("common:Delete")} ${t("Test Result")}`}
                message={`${t("message:DeleteRecordConfirm", {
                    entity: `${t("Test Result")}`,
                })}`}
                onToggle={onDeleteToggleHandler}
                onDelete={onDeleteRequestsHandler}
            />
            <ConfirmModal2
                modal={confirmSendResultModal}
                title={`${t("Result")}`}
                message={`${t("testResultPage:Approved Valid Publish")}`}
                onToggle={() => setConfirmSendResultModal(prev => !prev)}
                onConfirm={() => { onCreateReport(testResult.id) }}
                btnConfirmText={t("Ok")}
                isEdit={true}
            />
        </React.Fragment >
    )
}

const ListTabName = [
    {
        id: 1,
        name: 'common:Detail'
    },
    {
        id: 2,
        name: 'History'
    },
]

CardDetail.propTypes = {
    testResult: PropTypes.object,
    onGetTestResultDetail: PropTypes.func,
    t: PropTypes.any,
}

const mapStateToProps = ({ testResult }) => {
    return ({
        testResult: testResult.testResult,
        loadingDetail: testResult.loadingDetail || false,
        isFastValidate: testResult.isFastValidate,
        userValidate: testResult.userValidate
    })
}


const mapDispatchToProps = dispatch => ({
    onGetTestResultDetail: id => dispatch(getTestResultDetail(id)),
    onRefresh: (id, callback) => dispatch(getTestResultResults({ id, callback })),
    onAddNewFileResult: (data, callback) => dispatch(addNewFileResult(data, callback)),
    onSetLoadingChangeDetail: (data) => dispatch(setLoadingChangeDetail(data)),
    onApprovedValidAndPublishResult: (data, callback) => dispatch(ApprovedValidAndPublishResult(data, callback)),
    onSignTestResultResults: (data, callback) => dispatch(SignTestResultResults(data, callback)),
    onUnsignTestResultResults: (data, callback) => dispatch(UnsignTestResultResults(data, callback)),
})

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(withRouter(withTranslation(["testResultPage", "common"])(CardDetail)))