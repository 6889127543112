import { CustomBootstrapTable } from "components/Common"
import { CustomTooltipButton } from "components/Common/Button"
import { Instrument_Translator, ModuleIds } from "constant"
import { indexCalculator } from "helpers/utilities"
import { useEffect, useState } from "react"
import { withTranslation } from "react-i18next"
import { connect, useSelector } from "react-redux"
import FilterForm from "./FilterForm"
const RESOURCE = ModuleIds.Instrument
const InstrumentExpressionTable = ({
  onSelect,
  onSelectAll,
  instrumentTranslators,
  onSearch,
  onSort,
  onRefresh,
  onPageChange,
  paging,
  onSizePerPageChange,
  onSubmitFilter,
  onEdit,
  onDelete,
  onClone,
  loading,
  t,
  updatedTime,
}) => {
  const [data, setData] = useState([])
  const [model, setModel] = useState({
    search: "",
  })
  const searchQuery = useSelector(state => state.machine.searchQueryInstrumentTranslator)
  const onResetHandler = () => {
    const initModel = {
      search: "",
    }
    setModel(initModel)
  }
  useEffect(() => {
    setModel(searchQuery)
  }, [searchQuery])
  useEffect(() => {
    setData(instrumentTranslators)
  }, [instrumentTranslators])

  const renderTestName = (dataString) => {
    if (dataString && dataString.includes('[')) {
      let dataArr = JSON.parse(dataString)
      let res = dataArr.map((row, index) => {
        return <div key={index}>{row.TestCode} - {row.TestName}</div>
      })
      return res
    }
    else {
      return '';
    }
  }
  const columns = [
    {
      dataField: "id",
      text: "#",
      style: { width: 50 },
      formatter: (cellContent, machine, index) => {
        return indexCalculator(paging.page, paging.size, index + 1)
      },
    },
    {
      dataField: "machineName",
      text: t("common:Name"),
      formatter: (cellContent, machine, index) => {
        return (
          <span>{machine.machineName}</span>
          // <Link to={`/Instrument/${machine.id}/view`}>{machine.name}</Link>
        )
      },
    },
    {
      dataField: "machineCode",
      text: t("Manage Code"),
    },
    {
      dataField: "testCode",
      text: t("Test Code"),
      formatter: (cellContent, machine, index) => {
        return (
          <div style={{
            display: 'flex',
            flexDirection: 'column',
            gap: '2px',
          }}>
            {machine.insCode &&
              <div>
                <label
                  key={index}
                  data-tip
                  data-for={`${machine.insCode}-${index}`}
                  style={{
                    color: "#5F5F5F",
                    backgroundColor: "#fff",
                    padding: "1px 5px",
                    marginRight: "5px",
                    borderRadius: "5px",
                    border: "1px solid #808080",
                  }}
                >
                  {machine.insCode}
                </label>
                {/* <div style={{ marginLeft: 5 }}>
                  {renderTestName(machine.testName)}
                </div> */}
              </div>
            }
            {machine.testCode2 &&
              <div>
                <label
                  key={index}
                  data-tip
                  data-for={`${machine.testCode2}-${index}`}
                  style={{
                    color: "#5F5F5F",
                    backgroundColor: "#fff",
                    padding: "1px 5px",
                    marginRight: "5px",
                    borderRadius: "5px",
                    border: "1px solid #808080",
                  }}
                >
                  {machine.testCode2}
                </label>
                {/* <div style={{ marginLeft: 5 }}>
                  {renderTestName(machine.testName2)}
                </div> */}
              </div>
            }
            {machine.testCode3 &&
              <div>
                <label
                  key={index}
                  data-tip
                  data-for={`${machine.testCode3}-${index}`}
                  style={{
                    color: "#5F5F5F",
                    backgroundColor: "#fff",
                    padding: "1px 5px",
                    marginRight: "5px",
                    borderRadius: "5px",
                    border: "1px solid #808080",
                  }}
                >
                  {machine.testCode3}
                </label>
                {/* <div style={{ marginLeft: 5 }}>
                  {renderTestName(machine.testName3)}
                </div> */}
              </div>
            }
            {machine.testCode4 &&
              <div>
                <label
                  key={index}
                  data-tip
                  data-for={`${machine.testCode4}-${index}`}
                  style={{
                    color: "#5F5F5F",
                    backgroundColor: "#fff",
                    padding: "1px 5px",
                    marginRight: "5px",
                    borderRadius: "5px",
                    border: "1px solid #808080",
                  }}
                >
                  {machine.testCode4}
                </label>
                {/* <div style={{ marginLeft: 5 }}>
                  {renderTestName(machine.testName4)}
                </div> */}
              </div>
            }
            {machine.testCode5 &&
              <div>
                <label
                  key={index}
                  data-tip
                  data-for={`${machine.testCode5}-${index}`}
                  style={{
                    color: "#5F5F5F",
                    backgroundColor: "#fff",
                    padding: "1px 5px",
                    marginRight: "5px",
                    borderRadius: "5px",
                    border: "1px solid #808080",
                  }}
                >
                  {machine.testCode5}
                </label>
                {/* <div style={{ marginLeft: 5 }}>
                  {renderTestName(machine.testName5)}
                </div> */}
              </div>
            }
          </div>
        )
      },
    },
    {
      dataField: "age",
      text: t("common:Age"),
      formatter: (cellContent, item, index) => {
        return (
          <>
            {item.ageFrom}-{item.ageTo} ({item.ageTypeName})
          </>
        )
      },
    },
    // {
    //   dataField: "ageTypeName",
    //   headerStyle: { width: '80px' },
    //   text: t("common:Age Type"),
    // },
    {
      dataField: "genderName",
      headerStyle: { width: '80px' },
      text: t("common:Gender"),
    },
    {
      dataField: "numericRound",
      text: t("Numeric Round"),
      headerStyle: { width: '100px' },
      formatter: (cellContent, machine, index) => {
        return (
          <>
            {machine.type != Instrument_Translator.Replace && <span>{cellContent}</span>}
          </>
        )
      },
    },
    {
      dataField: "formula",
      text: t("Formula"),
      style: { maxWidth: '350px' },
      formatter: (cellContent, machine, index) => {
        return (
          <>
            {machine.type != Instrument_Translator.Replace && <span>{cellContent}</span>}
          </>
        )
      },
    },
    {
      dataField: "action",
      text: "",
      isDummyField: true,
      style: { width: 50 },
      formatter: (cellContent, item, index) => {
        return (
          <CustomTooltipButton
            index={index}
            component={item}
            onClone={onClone}
            onEdit={onEdit}
            onDelete={onDelete}
            isId
            t={t}
          />
        )
      },
    },
  ]
  return (
    <CustomBootstrapTable
      columns={columns}
      search
      data={data}
      paging={paging}
      onSelect={onSelect}
      onSelectAll={onSelectAll}
      onSearch={onSearch}
      onSort={onSort}
      onRefresh={onRefresh}
      filterForm={() =>
        <FilterForm model={model} />
      }
      onReset={onResetHandler}
      onPageChange={onPageChange}
      onSizePerPageChange={onSizePerPageChange}
      onSubmitFilter={onSubmitFilter}
      searchText={model.search}
      loading={loading}
      updatedTime={updatedTime}
      resource={RESOURCE}
    />
  )
}

const mapStateToProps = ({ }) => ({

})

const mapDispatchToProps = dispatch => ({

})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation(["machinePage", "common"])(InstrumentExpressionTable))