import { AvForm } from "availity-reactstrap-validation"
import {
  CustomAutoComplete,
  CustomAvField,
  CustomDatePicker,
  CustomSelect,
  CustomSelectAsync,
  CustomSelectButton,
  FormSwitch
} from "components/Common"
import {
  RESULT_STATE_Text,
  RequestPatientCondition,
  parameterCode
} from "constant"
import { getIndividualById, getPatientRequestById } from "helpers/app-backend"
import {
  emailRegex,
  formatPin,
  getInvalidMessageI18n,
  phoneRegex
} from "helpers/utilities"
import { isEmpty } from "lodash"

import React, { useEffect, useRef, useState } from "react"
import { withTranslation } from "react-i18next"
import { connect } from "react-redux"
import { Link, withRouter } from "react-router-dom"
import {
  Label,
  Table
} from "reactstrap"
import {
  emptyTestRequestDetail,
  getIndividualDetail,
  getTestrequestGeneralDetail,
  getTestrequestSamplesDetail,
  getTestrequestTestsDetail,
} from "store/actions"
import { getGeneralSetting } from "store/setting/generalSetting/actions"

let timeOut = null
let firstRender = true;
const GeneralInfo = ({
  t,
  formEl,
  dataRequest,
  onGetIndividualDetail,
  testRequestTests,
  testRequestSamples,
  onChangeData,
  onValidSubmit,
  fetchGetTestrequestGeneralDetail,
  fetchGetTestrequestTestsDetail,
  fetchGetTestrequestSamplesDetail,
  testRequest,
  oneEmptyTestRequestDetail,
  onGetGeneralSetting,
  generalSetting
}) => {
  const formRef = formEl ? formEl : useRef()
  const [data, setData] = useState(dataRequest)
  const [isRequiredPatient, setIsRequiredPatient] = useState(
    data.patientId && data.patientId != ""
  )
  const [popoverOpenPatientId, setPopoverOpenPatientId] = useState(false)
  const [popoverOpenPin, setPopoverOpenPin] = useState(false)
  const [popoverOpenPhone, setPopoverOpenPhone] = useState(false)
  const [popoverOpenRequest, setPopoverOpenRequest] = useState(false)
  const [popoverOpenName, setPopoverOpenName] = useState(false)
  const [requestState, setRequestState] = useState(0)
  const [isChangeData, setIsChangeData] = useState(false)
  const [companyId, setCompanyId] = useState(null)
  const [requestLabel, setRequestLabel] = useState("")
  const [oldRequestId, setOldRequestId] = useState("")
  const [phoneAndPinHidden, setPhoneAndPinHidden] = useState(false)
  const userInfo = JSON.parse(localStorage.getItem("userInfo"))
  const date = data.dob
    ? (new Date().getFullYear() - new Date(data.dob).getFullYear() || 0)
    : data.numberOfAge
  useEffect(() => {
    if (isEmpty(generalSetting)) {
      onGetGeneralSetting(userInfo?.company);
    }
    return () => {
      firstRender = true
      oneEmptyTestRequestDetail()
    }
  }, [])
  useEffect(() => {
    setData(dataRequest)
    setIsRequiredPatient(
      dataRequest.patientId != undefined && dataRequest.patientId != ""
    )
    setRequestLabel(dataRequest.requestLabel || "")
    setOldRequestId(dataRequest.requestId || "")
  }, [dataRequest])

  useEffect(() => {
    if (firstRender) {//ko cho lấy data từ test request
      firstRender = false
      return;
    }
    setCompanyId(testRequest.companyId)
    if (testRequest.id == "" || testRequest.id == undefined) {
      setPopoverOpenRequest(false)
    }
    if (testRequest.patientId && testRequest.patientId != "") {
      getPatientInfo()
    } else {
      const newData = {
        ...data,
        requestDate: testRequest.requestDate,
        requestid: testRequest.id,
        alternateId: testRequest.alternateId,
        patientId: testRequest.patientId,
        patient: "",
        medicalId: testRequest.medicalId,
        fullName: ``,
        insuranceNumber: testRequest.insuranceNumber,
        inPatient: testRequest.inPatient,
        emergency: testRequest.emergency,
        serviceType: testRequest.serviceType,
        dob: "",
        physicianId: testRequest.physicianId,
        physicianName: testRequest.physicianName,
        gender: "",
        departmentId: testRequest.departmentId,
        phone: "",
        diagnosis: testRequest.diagnosis,
        address: "",
        resultTime: testRequest.resultTime,
        email: "",
        remark: testRequest.remark,
        companyId: testRequest.companyId,
        pin: ``,
      }
      testRequest.requestDate && setData(newData)
      testRequest.requestDate && onChangeData && onChangeData(newData, true)
    }
  }, [testRequest])
  useEffect(() => {
    setIsChangeData(true)
    if (testRequestTests.length > 0) {
      setRequestState(RESULT_STATE_Text.Submitted)
      if (testRequestSamples.length > 0) {
        if (
          testRequestSamples[0].isReceived == true
          // && samples[0].isCollected == true
        ) {
          setRequestState(RESULT_STATE_Text.Received)
        } else if (testRequestSamples[0].isCollected == true) {
          setRequestState(RESULT_STATE_Text.Collected)
        } else {
          setRequestState(RESULT_STATE_Text.Submitted)
        }
      }
    } else {
      setRequestState(RESULT_STATE_Text.Draft)
    }
  }, [testRequestTests, testRequestSamples])

  const getPatientInfo = async () => {
    const res = await getPatientRequestById(
      testRequest.id,
      testRequest.patientId
    )
    const newData = {
      ...data,
      requestDate: testRequest.requestDate,
      requestid: testRequest.id,
      alternateId: testRequest.alternateId,
      patientId: testRequest.patientId,
      patient: res.id,
      medicalId: testRequest.medicalId,
      insuranceNumber: testRequest.insuranceNumber,
      inPatient: testRequest.inPatient,
      emergency: testRequest.emergency,
      fullName: `${res.familyName}${res.givenName ? " " + res.givenName : ""}`,
      serviceType: testRequest.serviceType,
      dob: res.dob,
      physicianId: testRequest.physicianId,
      physicianName: testRequest.physicianName,
      gender: res.gender,
      departmentId: testRequest.departmentId,
      phone: res["Contact.PhoneNumber"],
      diagnosis: testRequest.diagnosis,
      address: res["Address.Address"],
      resultTime: testRequest.resultTime,
      email: res["Contact.EmailAddress"],
      remark: testRequest.remark,
      companyId: testRequest.companyId,
      pin: res.PIN,
    }
    setData(newData)
    onChangeData && onChangeData(newData)
  }
  const onPhysicianIdChange = async value => {
    if (value != undefined) await fetchIndividualById(value)
  }

  const fetchIndividualById = async id => {
    const res = await getIndividualById(id)
    setCompanyId(res.ManagementCompanyId)
    onChangeData &&
      onChangeData({
        ...data,
        departmentId: res.Department,
        physicianId: id,
        companyId: res.ManagementCompanyId,
      })
    onInputDebounce("fetchIndividualById")
  }

  // const onInputDebounce = debounce(() => {
  //     setIsChangeData(false)
  //     auToSave()
  // }, 10000)

  const onInputDebounce = val => {
    if (timeOut) {
      clearInterval(timeOut)
    }
    timeOut = setInterval(() => {
      setIsChangeData(false)
      clearInterval(timeOut)
      auToSave()
    }, 2000)
  }

  let requestConfig = localStorage.getItem("requestConfig")
  const [config, setConfig] = useState({})
  useEffect(() => {
    setConfig(requestConfig ? JSON.parse(requestConfig) : {})
  }, [localStorage.getItem("requestConfig")])

  const auToSave = () => {
    config.autoSave == true && formRef && formRef.current?.submit()
  }
  const loadOldRequest = values => {
    setData({
      ...data,
      requestid: values[0].value,
      requestLabel: values[0].label,
    })
    fetchGetTestrequestTestsDetail(values[0].value)
    fetchGetTestrequestSamplesDetail(values[0].value)
    fetchGetTestrequestGeneralDetail(values[0].value)
  }
  const checkIsChange = (oldVal, newVal) => {
    // if (oldVal == newVal)
    //     return false
    // return true;
    return isChangeData
  }

  const focusAutoComplete = id => {
    setTimeout(() => {
      let el = document.getElementById("select-customautocomplete-" + id)
      el?.querySelectorAll("input")[0].focus()
    }, 500)
  }

  const displayInPatient = generalSetting.find(item => item.fieldName === 'InPatient')?.fieldValue === 'True';
  const displayEmergency = generalSetting.find(item => item.fieldName === 'Emergency')?.fieldValue === 'True';

  return (
    <React.Fragment>
      <AvForm
        ref={formRef}
        onValidSubmit={(e, vals) => {
          vals.requestid = testRequest.id
          onValidSubmit && onValidSubmit(vals)
        }}
        style={{ position: "relative" }}
      >
        {/* <div
          className="my-2 d-md-block d-sm-none d-xs-none"
          style={{ position: "absolute", top: "-45px", left: "47%" }}
        >
          <div className="d-flex">
            <Label className="font-size-14">&nbsp;</Label>
            <BadgeLabel
              value={t(`${RESULT_STATE[requestState]}`)}
              type={RESULT_STATE[requestState]}
              className="badge-width"
            />
          </div>
        </div>
        <div className="my-2 d-md-none">
          <div className="d-flex" style={{ placeContent: "center" }}>
            <Label className="font-size-14">&nbsp;</Label>
            <BadgeLabel
              value={t(`${RESULT_STATE[requestState]}`)}
              type={RESULT_STATE[requestState]}
              className="badge-width"
            />
          </div>
        </div> */}
        <div className="">
          <Table striped className="manual-test-request-table">
            <tr>
              <td>
                <div className="row align-items-center p-0">
                  <div className="col-md-2 pdr-0">
                    <Label
                      for="patientId"
                      className="font-weight-semibold font-size-14 align-self-end mb-0"
                    >
                      {t("PatientId")}
                      {isRequiredPatient && (
                        <span className="text-danger">*</span>
                      )}
                      :
                    </Label>
                  </div>
                  <div className="col-md-4 field-worklist pdl-0">
                    {popoverOpenPatientId ||
                      data.patientId == undefined ||
                      data.patientId == "" ? (
                      <CustomAutoComplete
                        name="patientId"
                        label={""}
                        iconSearch={true}
                        required
                        value={data.patientId || ""}
                        code={parameterCode.PATIENTS_BY_CONDITION}
                        type={RequestPatientCondition.PatientId}
                        resetTimeout={() => {
                          if (timeOut) {
                            clearInterval(timeOut)
                          }
                        }}
                        isDebounce={true}
                        group={RequestPatientCondition.PatientId}
                        errorMessage={getInvalidMessageI18n(t, "PatientId")}
                        onChange={(a, b, values) => {
                          if (values && values.length > 0)
                            if (values[0].id) {
                              onGetIndividualDetail({
                                id: values[0].id,
                                callback: res => {
                                  const newVal = {
                                    patient: values[0].id,
                                    patientId: res.PatientId,
                                    fullName: res.FullName,
                                    dob: res.DOB,
                                    gender: res.Gender,
                                    phone: res["Contact.PhoneNumber"],
                                    address: res["Address.Address"],
                                    email: res["Contact.EmailAddress"],
                                    pin: res.Identifier,
                                  }
                                  setPopoverOpenPatientId(false)
                                  onChangeData &&
                                    onChangeData({
                                      ...data,
                                      ...newVal,
                                      patientId: res.PatientId,
                                    })
                                  onInputDebounce("patientId1")
                                  setPhoneAndPinHidden(true)
                                },
                              })
                            } else {
                              setPopoverOpenPatientId(false)
                              onChangeData &&
                                onChangeData({
                                  ...data,
                                  patientId: values[0].value
                                    .replace(/[^a-zA-Z0-9]/g, "")
                                    .replace(" ", ""),
                                  patient: "",
                                })
                              onInputDebounce("patientId2")
                            }
                          else {
                            setPopoverOpenPatientId(false)
                            onChangeData &&
                              onChangeData({
                                ...data,
                                patientId: "",
                                patient: "",
                              })
                            onInputDebounce("patientId3")
                          }
                        }}
                      />
                    ) : (
                      <div className="d-flex">
                        <Label
                          style={{ flex: 1 }}
                          className="font-weight-semibold font-size-14 align-self-end mb-0"
                          onClick={() => {
                            setPopoverOpenPatientId(true)
                            focusAutoComplete("patientId")
                          }}
                        >
                          {data.patientId}
                        </Label>
                        <div
                          onClick={() => {
                            setPopoverOpenPatientId(true)
                            focusAutoComplete("patientId")
                          }}
                        >
                          <i className="fa fa-search"></i>
                        </div>
                      </div>
                    )}
                  </div>

                  <div className="col-md-2 pdr-0">
                    <Label
                      for="pin"
                      className="font-weight-semibold font-size-14 align-self-end mb-0"
                    >
                      {t("Identifier")}:
                    </Label>
                  </div>
                  <div className="col-md-4 field-worklist pdl-0">
                    {popoverOpenPin ||
                      data.pin == undefined ||
                      data.pin == "" ? (
                      !phoneAndPinHidden && <CustomAutoComplete
                        name="pin"
                        pinNumber
                        label={""}
                        iconSearch={true}
                        isDebounce={true}
                        value={
                          data.pin == null ? "" : formatPin(data.pin) || ""
                        }
                        code={parameterCode.PATIENTS_BY_CONDITION}
                        type={RequestPatientCondition.PIN}
                        group={RequestPatientCondition.PIN}
                        errorMessage={getInvalidMessageI18n(t, "Identifier")}
                        placeholder={t("Identifier")}
                        resetTimeout={() => {
                          if (timeOut) {
                            clearInterval(timeOut)
                          }
                        }}
                        onChange={(a, b, values) => {
                          if (values && values.length > 0) {
                            if (values[0].id) {
                              onGetIndividualDetail({
                                id: values[0].id,
                                callback: res => {
                                  const newVal = {
                                    patient: values[0].id,
                                    patientId: res.PatientId,
                                    fullName: res.FullName,
                                    dob: res.DOB,
                                    gender: res.Gender,
                                    phone: res["Contact.PhoneNumber"],
                                    address: res["Address.Address"],
                                    email: res["Contact.EmailAddress"],
                                    pin: res.Identifier,
                                  }
                                  setPopoverOpenPin(false)
                                  onChangeData &&
                                    onChangeData({
                                      ...data,
                                      ...newVal,
                                      pin: res.Identifier,
                                    })
                                  onInputDebounce("pin1")
                                },
                              })
                            } else {
                              setPopoverOpenPin(false)
                              onChangeData &&
                                onChangeData({
                                  ...data,
                                  pin: values[0].value.replace(/[^0-9]/g, ""),
                                })
                              onInputDebounce("pin1")
                            }
                          } else {
                            setPopoverOpenPin(false)
                            onChangeData && onChangeData({ ...data, pin: "" })
                            onInputDebounce("pin1")
                          }
                        }}
                      />
                    ) : (
                      <div className="d-flex">
                        <Label
                          style={{ flex: 1 }}
                          className="font-weight-semibold font-size-14 align-self-end mb-0"
                          onClick={() => {
                            setPopoverOpenPin(true)
                            focusAutoComplete("pin")
                          }}
                        >
                          {phoneAndPinHidden ? '' : formatPin(data.pin)}
                        </Label>
                        <div
                          onClick={() => {
                            setPopoverOpenPin(true)
                            focusAutoComplete("pin")
                          }}
                        >
                          <i className="fa fa-search"></i>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </td>
              <td>
                <div className="row align-items-center p-0 ">
                  <div className="d-none">
                    <CustomAvField
                      name="requestid"
                      type="hidden"
                      value={data.requestid || testRequest.id || ""}
                    />
                    <CustomAvField
                      name="companyId"
                      type="hidden"
                      value={companyId || data.companyId || 1}
                    />
                  </div>
                  <div className="col-md-2 pdr-0">
                    <Label className="font-size-14 font-weight-semibold mb-0">
                      {t("Request Id")}:
                    </Label>
                  </div>
                  <div className="col-md-4">
                    {popoverOpenRequest == false ? (
                      <div
                        className="d-flex"
                        style={{ flex: 1, alignSelf: "center" }}
                      >
                        <Label
                          style={{ flex: 1 }}
                          className="font-size-14 font-weight-semibold mb-0"
                        >
                          <Link
                            target="_blank"
                            to={`/TestRequest/${data.requestid || testRequest.id
                              }/view?tab=1`}
                          >
                            {requestLabel || ""}
                          </Link>
                        </Label>
                        <div
                          onClick={() => {
                            setPopoverOpenRequest(true)
                            setTimeout(() => {
                              let el = document.getElementById(
                                "requestidSearchselect-customasync"
                              )
                              el.querySelectorAll("input")[0].focus()
                            }, 500)
                          }}
                        >
                          <i className="fa fa-search"></i>
                        </div>
                      </div>
                    ) : (
                      <CustomSelectAsync
                        name="requestidSearch"
                        value={oldRequestId || ""}
                        label={""}
                        valueName={requestLabel || ""}
                        code={parameterCode.TEST_REQUEST_LIST}
                        isManualRequest={true}
                        onChange={(a, b, c, values) => {
                          if (values && values.length > 0) {
                            setRequestLabel(values[0].label)
                            setOldRequestId(values[0].value)
                            loadOldRequest(values)
                          } else {
                            setRequestLabel("")
                            setOldRequestId("")
                            onChangeData &&
                              onChangeData({
                                ...data,
                                requestid: "",
                                requestLabel: "",
                              })
                          }
                          setPhoneAndPinHidden(true)
                          setPopoverOpenRequest(false)
                        }}
                      />
                    )}
                  </div>
                  <div className="col-md-2 pdr-0">
                    <Label
                      for="alternateId"
                      className="font-weight-semibold font-size-14 align-self-end mb-0"
                    >
                      {t("Alternate Request ID")}:
                    </Label>
                  </div>
                  <div className="col-md-4 field-worklist pdl-0">
                    <CustomAvField
                      name="alternateId"
                      value={data.alternateId || ""}
                      errorMessage={getInvalidMessageI18n(
                        t,
                        "Alternate Request ID"
                      )}
                      validate={{
                        pattern: {
                          value: "^[A-Za-z0-9]+$",
                        },
                      }}
                      type="text"
                      placeholder={t("Mã thay thế/Quét Barcode")}
                      onChange={val => {
                        setIsChangeData(true)
                        onChangeData &&
                          onChangeData({ ...data, alternateId: val })
                      }}
                      onBlur={e => {
                        if (checkIsChange(data.alternateId, e.target.value)) {
                          onInputDebounce("alternateId")
                        }
                      }}
                    />
                  </div>
                </div>
              </td>
            </tr>
            <tr>
              <td>
                <div className="row align-items-center p-0">
                  <div className="col-md-2 pdr-0">
                    <Label
                      for="fullName"
                      className="font-weight-semibold font-size-14 align-self-end mb-0"
                    >
                      {t("Full Name")}
                      {isRequiredPatient && (
                        <span className="text-danger">*</span>
                      )}
                      :
                    </Label>
                  </div>
                  <div className="col-md-10 field-worklist pdl-0">
                    {popoverOpenName ||
                      data.fullName == undefined ||
                      data.fullName == "" ? (
                      <CustomAutoComplete
                        name="fullName"
                        label={""}
                        isDebounce={true}
                        resetTimeout={() => {
                          if (timeOut) {
                            clearInterval(timeOut)
                          }
                        }}
                        value={data.fullName || ""}
                        code={parameterCode.PATIENTS_BY_CONDITION}
                        group={RequestPatientCondition.PatientName}
                        type={RequestPatientCondition.PatientName}
                        errorMessage={getInvalidMessageI18n(t, "Full Name")}
                        placeholder={t("Full Name")}
                        required
                        onChange={(a, b, values) => {
                          if (values && values.length > 0) {
                            if (values[0].id) {
                              onGetIndividualDetail({
                                id: values[0].id,
                                callback: res => {
                                  const newVal = {
                                    patient: values[0].id,
                                    patientId: res.PatientId,
                                    fullName: res.FullName,
                                    dob: res.DOB,
                                    gender: res.Gender,
                                    phone: res["Contact.PhoneNumber"],
                                    address: res["Address.Address"],
                                    email: res["Contact.EmailAddress"],
                                    pin: res.Identifier,
                                  }
                                  setPopoverOpenName(false)
                                  onChangeData &&
                                    onChangeData({
                                      ...data,
                                      ...newVal,
                                      fullName: res.FullName,
                                    })
                                  onInputDebounce("fullName1")
                                  setPhoneAndPinHidden(true)
                                },
                              })
                            } else {
                              setPopoverOpenName(false)
                              onChangeData &&
                                onChangeData({
                                  ...data,
                                  fullName: values[0].value,
                                })
                              onInputDebounce("fullName2")
                            }
                          } else {
                            setPopoverOpenName(false)
                            onChangeData &&
                              onChangeData({ ...data, fullName: "" })
                            onInputDebounce("fullName3")
                          }
                        }}
                      />
                    ) : (
                      <div className="d-flex">
                        <Label
                          style={{ flex: 1 }}
                          className="font-weight-semibold font-size-14 align-self-end mb-0"
                          onClick={() => {
                            setPopoverOpenName(true)
                            focusAutoComplete("fullName")
                          }}
                        >
                          {data.fullName}
                        </Label>
                        <div
                          onClick={() => {
                            setPopoverOpenName(true)
                            focusAutoComplete("fullName")
                          }}
                        >
                          <i className="fa fa-search"></i>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </td>
              <td>
                <div className="row align-items-center p-0">
                  <div className="col-md-2 pdr-0">
                    <Label
                      for="medicalId"
                      className="font-weight-semibold font-size-14 align-self-end mb-0"
                    >
                      {t("Medical ID")}:
                    </Label>
                  </div>
                  <div className="col-md-4 field-worklist pdl-0">
                    <CustomAvField
                      name="medicalId"
                      value={data.medicalId || ""}
                      errorMessage={getInvalidMessageI18n(t, "Medical ID")}
                      type="text"
                      placeholder={"Mã y tế"}
                      validate={{
                        pattern: {
                          value: "^[A-Za-z0-9]+$",
                        },
                      }}
                      onChange={val => {
                        setIsChangeData(true)
                        onChangeData &&
                          onChangeData({ ...data, medicalId: val })
                      }}
                      onBlur={e => {
                        if (checkIsChange(data.medicalId, e.target.value)) {
                          onInputDebounce("medicalId")
                        }
                      }}
                    />
                  </div>
                  <div className="col-md-2 pdr-0">
                    <Label
                      for="insuranceNumber"
                      className="font-weight-semibold font-size-14 align-self-end mb-0"
                    >
                      {t("Insurance Number")}:
                    </Label>
                  </div>
                  <div className="col-md-4 field-worklist pdl-0">
                    <CustomAvField
                      name="insuranceNumber"
                      value={data.insuranceNumber || ""}
                      errorMessage={getInvalidMessageI18n(
                        t,
                        "Insurance Number"
                      )}
                      type="text"
                      placeholder={t("Insurance Code")}
                      onChange={val => {
                        setIsChangeData(true)
                        onChangeData &&
                          onChangeData({ ...data, insuranceNumber: val })
                      }}
                      onBlur={e => {
                        if (
                          checkIsChange(data.insuranceNumber, e.target.value)
                        ) {
                          onInputDebounce("insuranceNumber")
                        }
                      }}
                    />
                  </div>
                </div>
              </td>
            </tr>
            <tr>
              <td>
                <div className="row align-items-center p-0">
                  <div className="col-md-2 pdr-0">
                    <Label
                      for="gender"
                      className="font-weight-semibold font-size-14 align-self-end mb-0"
                    >
                      {t("Gender")}
                      {isRequiredPatient && (
                        <span className="text-danger">*</span>
                      )}
                      :
                    </Label>
                  </div>
                  <div className="col-md-4 field-worklist pdl-0 align-items-center p-0 mt-3">
                    <CustomSelectButton
                      name="gender"
                      label={""}
                      value={data.gender || "F"}
                      code={parameterCode.GENDER}
                      required={isRequiredPatient || false}
                      validate={{
                        required: { value: isRequiredPatient || false },
                      }}
                      type="radio"
                      errorMessage={getInvalidMessageI18n(t, "Gender")}
                      onChange={(e, vals) => {
                        onChangeData &&
                          onChangeData({ ...data, gender: vals[0] })
                        onInputDebounce("gender")
                      }}
                    />
                  </div>

                  <div
                    className="row col-sm-6"
                    style={{ justifyContent: "end" }}
                  >
                    {displayInPatient && (
                      <div className="row align-items-center p-0 col-md-6 mr-1">
                        <div className="col-md-6">
                          <Label
                            for="inPatient"
                            className="font-weight-semibold font-size-14 align-self-end mb-0"
                          >
                            {t("In Patient")}:
                          </Label>
                        </div>
                        <div className="col-md-6">
                          <FormSwitch
                            label=""
                            value={data.inPatient}
                            onChangeSwitch={value => {
                              setIsChangeData(true)
                              onChangeData &&
                                onChangeData({ ...data, inPatient: value })
                            }}
                          />
                        </div>
                      </div>
                    )}
                    {displayEmergency && (
                      <div className="row align-items-center p-0 col-md-6">
                        <div className="col-md-6">
                          <Label
                            for="emergency"
                            className="font-weight-semibold font-size-14 align-self-end mb-0"
                          >
                            {t("common:Emergency")}:
                          </Label>
                        </div>
                        <div className="col-md-6">
                          <FormSwitch
                            label=""
                            style={{
                              backgroundColor: data.emergency ? "#eb3c27" : "",
                              borderColor: data.emergency ? "#eb3c27" : "",
                            }}
                            value={data.emergency}
                            onChangeSwitch={value => {
                              setIsChangeData(true)
                              onChangeData &&
                                onChangeData({ ...data, emergency: value })
                            }}
                          />
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </td>
              <td>
                <div className="row align-items-center p-0">
                  <div className="col-md-2 pdr-0">
                    <Label
                      for="requestDate"
                      className="font-weight-semibold font-size-14 align-self-end mb-0"
                    >
                      {t("Request Date")}
                      <span className="text-danger">*</span>:
                    </Label>
                  </div>
                  <div className="col-md-4 pdl-0">
                    <CustomDatePicker
                      name="requestDate"
                      placeholder={"DD/MM/YYYY"}
                      format={"DD/MM/YYYY"}
                      value={data.requestDate || new Date()}
                      required
                      closeOnSelect={true}
                      onChangeHandler={val => {
                        if (checkIsChange(data.requestDate, val.time[0])) {
                          onChangeData &&
                            onChangeData({
                              ...data,
                              requestDate: val.time[0],
                            })
                          onInputDebounce("requestDate")
                        }
                      }}
                    />
                  </div>
                  <div className="col-md-2 pdr-0">
                    <Label
                      for="serviceType"
                      className="font-weight-semibold font-size-14 align-self-end mb-0"
                    >
                      {t("Type")}
                      <span className="text-danger">*</span>:
                    </Label>
                  </div>
                  <div className="col-md-4 field-worklist pdl-0">
                    <CustomSelect
                      name="serviceType"
                      label={""}
                      value={data.serviceType || 3}
                      code={parameterCode.SERVICE_TYPE}
                      required={true}
                      errorMessage={getInvalidMessageI18n(t, "Type")}
                      onChange={(e, vals) => {
                        onChangeData &&
                          onChangeData({ ...data, serviceType: vals[0] })
                        onInputDebounce("serviceType")
                      }}
                    />
                  </div>
                </div>
              </td>
            </tr>
            <tr>
              <td>
                <div className="row align-items-center p-0">
                  <div className="col-md-2 pdr-0">
                    <Label
                      for="dob"
                      className="font-weight-semibold font-size-14 align-self-end mb-0"
                    >
                      {t("DOB")}
                      {isRequiredPatient && (
                        <span className="text-danger">*</span>
                      )}
                      :
                    </Label>
                  </div>
                  <div className="col-md-4 field-worklist pdl-0">
                    <CustomDatePicker
                      name="dob"
                      placeholder={"DD/MM/YYYY"}
                      format={"DD/MM/YYYY"}
                      value={data.dob || new Date()}
                      required
                      maxDate={new Date()}
                      closeOnSelect={true}
                      validate={{
                        required: { value: isRequiredPatient },
                      }}
                      errorMessage={getInvalidMessageI18n(t, "DOB")}
                      onChangeHandler={val => {
                        const numberOfAge =
                          new Date().getFullYear() -
                          new Date(val.time[0]).getFullYear() || 0
                        onChangeData &&
                          onChangeData({
                            ...data,
                            dob: val.time[0],
                            numberOfAge,
                          })
                        onInputDebounce("dob")
                      }}
                    />
                  </div>
                  <div className="col-md-2 pdr-0 ">
                    <Label
                      for="numberOfAge"
                      className="font-weight-semibold font-size-14 align-self-end mb-0"
                    >
                      {t("Age")}
                      {isRequiredPatient && (
                        <span className="text-danger">*</span>
                      )}
                      :
                    </Label>
                  </div>
                  <div className="col-md-4 field-worklist pdl-0">
                    <CustomAvField
                      type="number"
                      min={0}
                      max={150}
                      name="numberOfAge"
                      value={date}
                      errorMessage={getInvalidMessageI18n(t, "Number of age")}
                      placeholder={t("Number of age")}
                      onChange={val => {
                        setIsChangeData(true)
                        const dob = new Date().setFullYear(
                          new Date().getFullYear() - val || 0
                        )
                        const formatDOB = new Date(dob).toISOString()
                        onChangeData &&
                          onChangeData({
                            ...data,
                            numberOfAge: val,
                            dob: formatDOB,
                          })
                        onInputDebounce("dob")
                      }}
                    />
                  </div>
                </div>
              </td>
              <td>
                <div className="row align-items-center p-0">
                  <div className="col-md-2 pdr-0">
                    <Label
                      for="physicianId"
                      className="font-weight-semibold font-size-14 align-self-end mb-0"
                    >
                      {t("Request Physician")}
                      <span className="text-danger">*</span>:
                    </Label>
                  </div>
                  <div className="col-md-4 field-worklist pdl-0">
                    <CustomSelectAsync
                      name="physicianId"
                      label={""}
                      value={data.physicianId || ""}
                      valueName={data.physicianName || ""}
                      code={parameterCode.PHYSICIANS}
                      required={true}
                      errorMessage={getInvalidMessageI18n(
                        t,
                        "Request Physician"
                      )}
                      onChange={(e, values) => {
                        onPhysicianIdChange(values[0])
                      }}
                    />
                  </div>

                  <div className="col-md-2 pdr-0">
                    <Label
                      for="departmentId"
                      className="font-weight-semibold font-size-14 align-self-end mb-0"
                    >
                      {t("Department")}
                      <span className="text-danger">*</span>:
                    </Label>
                  </div>
                  <div className="col-md-4 field-worklist pdl-0">
                    <CustomSelect
                      name="departmentId"
                      label={""}
                      value={data.departmentId || ""}
                      code={parameterCode.DEPARTMENTS}
                      group={companyId || ""}
                      required={true}
                      errorMessage={getInvalidMessageI18n(t, "Department")}
                      onChange={(e, vals) => {
                        onChangeData &&
                          onChangeData({ ...data, departmentId: vals[0] })
                        onInputDebounce("departmentId")
                      }}
                    />
                  </div>
                </div>
              </td>
            </tr>
            <tr>
              <td>
                <div className="row align-items-center p-0">
                  <>
                    <div className="col-md-2 pdr-0">
                      <Label
                        for="phone"
                        className="font-weight-semibold font-size-14 align-self-end mb-0"
                      >
                        {t("Phone")}:
                      </Label>
                    </div>
                    <div className="col-md-4 field-worklist pdl-0">
                      {popoverOpenPhone ||
                        data.phone == undefined ||
                        data.phone == "" ? (
                        !phoneAndPinHidden && (<CustomAutoComplete
                          name="phone"
                          label={""}
                          resetTimeout={() => {
                            if (timeOut) {
                              clearInterval(timeOut)
                            }
                          }}
                          isDebounce={true}
                          value={data.phone || ""}
                          code={parameterCode.PATIENTS_BY_CONDITION}
                          group={RequestPatientCondition.Phone}
                          type={RequestPatientCondition.Phone}
                          errorMessage={getInvalidMessageI18n(t, "Phone")}
                          placeholder={t("Phone")}
                          onChange={(a, b, values) => {
                            if (values && values.length > 0)
                              if (values[0].id) {
                                onGetIndividualDetail({
                                  id: values[0].id,
                                  callback: res => {
                                    const newVal = {
                                      patient: values[0].id,
                                      patientId: res.PatientId,
                                      fullName: res.FullName,
                                      dob: res.DOB,
                                      gender: res.Gender,
                                      phone: res["Contact.PhoneNumber"],
                                      address: res["Address.Address"],
                                      email: res["Contact.EmailAddress"],
                                      pin: res.Identifier,
                                    }
                                    setPopoverOpenPhone(false)
                                    onChangeData &&
                                      onChangeData({
                                        ...data,
                                        ...newVal,
                                        phone: res["Contact.PhoneNumber"],
                                      })
                                    onInputDebounce("Phone1")
                                  },
                                })
                              } else {
                                setPopoverOpenPhone(false)
                                const isPhone = phoneRegex.test(values[0].value)
                                onChangeData &&
                                  onChangeData({
                                    ...data,
                                    phone: isPhone ? values[0].value : "",
                                  })
                                onInputDebounce("Phone2")
                              }
                            else {
                              setPopoverOpenPhone(false)
                              onChangeData && onChangeData({ ...data, phone: "" })
                              onInputDebounce("Phone3")
                            }
                          }}
                        />)
                      ) : (
                        <div className="d-flex">
                          <Label
                            style={{ flex: 1 }}
                            className="font-weight-semibold font-size-14 align-self-end mb-0"
                            onClick={() => {
                              setPopoverOpenPhone(true)
                              focusAutoComplete("phone")
                            }}
                          >
                            {phoneAndPinHidden ? '' : data.phone}
                          </Label>
                          <div
                            onClick={() => {
                              setPopoverOpenPhone(true)
                              focusAutoComplete("phone")
                            }}
                          >
                            <i className="fa fa-search"></i>
                          </div>
                        </div>
                      )}
                    </div>
                  </>
                  <div className="col-md-2 pdr-0">
                    <Label
                      for="email"
                      className="font-weight-semibold font-size-14 align-self-end mb-0"
                    >
                      {t("Email")}:
                    </Label>
                  </div>
                  <div className="col-md-4 field-worklist pdl-0">
                    <CustomAvField
                      name="email"
                      value={data.email || ""}
                      errorMessage={getInvalidMessageI18n(t, "Email")}
                      type="text"
                      placeholder={t("Email")}
                      onChange={val => {
                        setIsChangeData(true)
                        onChangeData && onChangeData({ ...data, email: val })
                      }}
                      validate={{
                        pattern: {
                          value: emailRegex,
                        },
                      }}
                      onBlur={e => {
                        if (checkIsChange(data.email, e.target.value)) {
                          onInputDebounce("email")
                        }
                      }}
                    />
                  </div>
                </div>
              </td>
              <td>
                <div className="row align-items-center p-0">
                  <div className="col-md-2 pdr-0">
                    <Label
                      for="diagnosis"
                      className="font-weight-semibold font-size-14 align-self-end mb-0"
                    >
                      {t("Diagnosis")}:
                    </Label>
                  </div>
                  <div className="col-md-10 field-worklist pdl-0">
                    <CustomAutoComplete
                      name="diagnosis"
                      iconSearch={true}
                      label={""}
                      resetTimeout={() => {
                        if (timeOut) {
                          clearInterval(timeOut)
                        }
                      }}
                      value={data.diagnosis || ""}
                      code={parameterCode.DIAGNOSIS_KEY}
                      errorMessage={getInvalidMessageI18n(t, "Diagnosis")}
                      onChange={(a, vals) => {
                        onChangeData &&
                          onChangeData({ ...data, diagnosis: vals[0] })
                        onInputDebounce("Diagnosis")
                      }}
                    />
                  </div>
                </div>
              </td>
            </tr>
            <tr>
              <td>
                <div className="row align-items-center p-0">
                  <div className="col-md-2 pdr-0">
                    <Label
                      for="address"
                      className="font-weight-semibold font-size-14 align-self-end mb-0"
                    >
                      {t("Address")}:
                    </Label>
                  </div>
                  <div className="col-md-10 field-worklist pdl-0">
                    <CustomAvField
                      name="address"
                      value={data.address || ""}
                      errorMessage={getInvalidMessageI18n(t, "Address")}
                      type="text"
                      placeholder={t("Address")}
                      onChange={val => {
                        setIsChangeData(true)
                        onChangeData && onChangeData({ ...data, address: val })
                      }}
                      onBlur={e => {
                        if (checkIsChange(data.address, e.target.value)) {
                          onInputDebounce("Address")
                        }
                      }}
                    />
                  </div>
                </div>
              </td>
              <td>
                <div className="row align-items-center p-0">
                  <div className="col-md-2 pdr-0">
                    <Label
                      for="remark"
                      className="font-weight-semibold font-size-14 align-self-end mb-0"
                    >
                      {t("Remark")}:
                    </Label>
                  </div>
                  <div className="col-md-10 field-worklist pdl-0">
                    <CustomAvField
                      name="remark"
                      type="textarea"
                      rows={1}
                      value={data.remark || ""}
                      onChange={val => {
                        setIsChangeData(true)
                        onChangeData && onChangeData({ ...data, remark: val })
                      }}
                      onBlur={e => {
                        if (checkIsChange(data.remark, e.target.value)) {
                          onInputDebounce("remark")
                        }
                      }}
                    />
                  </div>
                </div>
              </td>
            </tr>
          </Table>
        </div>
      </AvForm>
    </React.Fragment>
  )
}

const mapStateToProps = ({ testRequest, generalSetting }) => {
  return {
    billingTypes: testRequest.billingTypes,
    testRequestTests: testRequest.testRequest.tests || [],
    testRequestSamples: testRequest.testRequest.samples || [],
    testRequest: testRequest.testRequest,
    generalSetting: generalSetting.generalSetting
  }
}

const mapDispatchToProps = dispatch => ({
  onGetIndividualDetail: individualId =>
    dispatch(getIndividualDetail(individualId)),
  fetchGetTestrequestGeneralDetail: (requestId, callback) =>
    dispatch(getTestrequestGeneralDetail(requestId, callback)),
  fetchGetTestrequestTestsDetail: requestId =>
    dispatch(getTestrequestTestsDetail(requestId)),
  fetchGetTestrequestSamplesDetail: requestId =>
    dispatch(getTestrequestSamplesDetail(requestId)),
  oneEmptyTestRequestDetail: requestId =>
    dispatch(emptyTestRequestDetail(requestId)),
  onGetGeneralSetting: payload => dispatch(getGeneralSetting(payload)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(withTranslation(["testRequestPage", "message"])(GeneralInfo)))
