import BadgeLabelAndValue from "components/Common/BadgeLabelAndValue"

import { convertDateFormat, convertDateFormat_VN, getAgeFromDOB } from "helpers/utilities"

import {
    Accordion,
    AccordionBody,
    AccordionWrapper
} from "components/Common"
import React, { useEffect, useState } from "react"
import { withTranslation } from "react-i18next"
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"

const GeneralInfoWaitingRequest = ({
    t,
    patientRequestWaiting
}) => {
    const [data, setData] = useState({})
    const [open, setOpen] = useState(false)

    useEffect(() => {
        if (patientRequestWaiting && patientRequestWaiting.maYTe) {
            setData(patientRequestWaiting)
        }
    }, [patientRequestWaiting])

    return (
        <div className="header-result-detail sumamry-info-result-detail">
            <AccordionWrapper>
                <Accordion tabId={"1"} iconPosition='right'
                    onChange={(e, val) => {
                        setOpen(val)
                    }}
                    customTitle={() => <React.Fragment>
                        {open == true ?
                            <React.Fragment>
                                <div>{t("testRequestPage:Patient Infomation")}</div>
                                <div className="flex-1" style={{ textAlign: 'right' }}>
                                    {data.capCuu == true &&
                                        <label style={{ marginLeft: 5 }}>
                                            <div className="">
                                                <BadgeLabelAndValue
                                                    label=''
                                                    type={'danger'}
                                                    value={t(`testResultPage:CC`)}
                                                    className={"fw-500"}
                                                />
                                            </div>
                                        </label>
                                    }
                                </div>
                            </React.Fragment>
                            :
                            <React.Fragment>
                                <div className="header-result-detail-content d-flex header-result-detail-content-summary">
                                    <div>
                                        <label style={{ color: '#4775C5' }} className="header-result-detail-value">
                                            <label className="header-result-detail-value">{data.hoTen}</label>
                                        </label>
                                    </div>
                                    <div>
                                        <label className="header-result-detail-label">{t("Birthday")}: </label>
                                        <label className="header-result-detail-value">{convertDateFormat_VN(data.ngaySinh)}</label>
                                    </div>
                                    <div>
                                        <label className="header-result-detail-label">{t("Gender")}: </label>
                                        <label className="header-result-detail-value">{t(`common:${data.gioiTinh == "M" ? 'Male' : (data.gioiTinh == "F" ? 'Female' : '')}`)}</label>
                                    </div>
                                    <div>
                                        <label className="header-result-detail-label">{t("testRequestPage:Age")}: </label>
                                        <label className="header-result-detail-value">{data.ngaySinh ? getAgeFromDOB(convertDateFormat(data.ngaySinh, "YYYY-MM-DD")) : ''}</label>
                                    </div>
                                    <div>
                                        <label className="header-result-detail-label">{t("common:ServiceType")}: </label>
                                        <label className="header-result-detail-value">{data.objectName}</label>
                                    </div>
                                </div>
                                <div className="flex-1" style={{ textAlign: 'right' }}>
                                    {data.capCuu == true &&
                                        <label style={{ marginLeft: 5 }}>
                                            <div className="">
                                                <BadgeLabelAndValue
                                                    label=''
                                                    type={'danger'}
                                                    value={t(`testResultPage:CC`)}
                                                    className={"fw-500"}
                                                />
                                            </div>
                                        </label>
                                    }
                                </div>
                            </React.Fragment>
                        }
                    </React.Fragment>}
                >
                    <AccordionBody>
                        <div>
                            <div className="row">
                                <div className="col-lg-3 header-result-detail-content">
                                    <div>
                                        <label className="header-result-detail-label">{t("Full Name")}: </label>
                                        <label style={{ color: '#4775C5' }} className="header-result-detail-value">
                                            <label className="header-result-detail-value">{data.hoTen}</label>
                                        </label>
                                    </div>
                                    <div>
                                        <label className="header-result-detail-label">{t("Birthday")}: </label>
                                        <label className="header-result-detail-value">{convertDateFormat_VN(data.ngaySinh)}</label>
                                    </div>
                                    {/* <div>
                                        <label className="header-result-detail-label">{t("common:Address")}:
                                            <span className="header-result-detail-value">{data.address}</span>
                                        </label>
                                    </div> */}
                                </div>
                                <div className="col-lg-3 header-result-detail-content">
                                    <div>
                                        <label className="header-result-detail-label">{t("common:ServiceType")}: </label>
                                        <label className="header-result-detail-value">{data.objectName}</label>
                                    </div>
                                    <div>
                                        <label className="header-result-detail-label">{t("Gender")}: </label>
                                        <label className="header-result-detail-value">{t(`common:${data.gioiTinh == "M" ? 'Male' : (data.gioiTinh == "F" ? 'Female' : '')}`)}</label>
                                        <label style={{ marginLeft: '10px' }} className="header-result-detail-label">{t("testRequestPage:Age")}: </label>
                                        <label className="header-result-detail-value">{data.ngaySinh ? getAgeFromDOB(convertDateFormat(data.ngaySinh, "YYYY-MM-DD")) : ''}</label>
                                    </div>
                                </div>
                                <div className="col-lg-3 header-result-detail-content">
                                    <div>
                                        <label className="header-result-detail-label">{t("PID")}: </label>
                                        <label className="header-result-detail-value">{data.patientId}</label>
                                    </div>
                                    {/* {displayInPatient && <div>
                                        <label className="header-result-detail-label">{t("common:InPatient")}: </label>
                                        <label className="header-result-detail-value">
                                            <FormSwitch
                                                label=""
                                                disabled
                                                value={data.inPatient}
                                            />

                                        </label>
                                    </div>} */}
                                    <div>
                                        <label className="header-result-detail-label">{t("common:Department")}: </label>
                                        <label className="header-result-detail-value">{data.LocationName}</label>
                                    </div>
                                </div>
                                <div className="col-lg-3 header-result-detail-content">

                                    <div>
                                        <label className="header-result-detail-label">{t("common:Phycisian")}: <span className="header-result-detail-value">{data.doctorName}</span></label>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                            <div className="col-lg-6 mb-2 header-result-detail-content">
                                    <div>
                                        <label className="header-result-detail-label">{t("common:Address")}: <span className="header-result-detail-value">{data.address}</span></label>
                                    </div>
                                </div>
                                <div className="col-lg-6 mb-2 header-result-detail-content">
                                    <div>
                                        <label className="header-result-detail-label">{t("common:Diagnosis")}: <span className="header-result-detail-value">{data.chanDoan}</span></label>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </AccordionBody>
                </Accordion>
            </AccordionWrapper>
        </div>
    )
}

const mapStateToProps = ({ testResult }) => {
    return {
        patientRequestWaiting: testResult.patientRequestWaiting || {},
    }
}

const mapDispatchToProps = dispatch => ({
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(withTranslation(["testRequestPage", "message", "common", "patientVisitPage"])(GeneralInfoWaitingRequest)))
