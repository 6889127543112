import { TitleAndTable } from "components/Common"
import React, { useEffect, useState } from "react"
import { withTranslation } from "react-i18next"
import { connect } from "react-redux"
import VisitHistoryTable from "./VisitHistoryTable"

import {
  getPartyVisitsDetail,
  resetPartyVisitsSearchQuery,
  setPartyVisitsSearchQuery
} from "store/party/party/actions"

import GeneralHistoryModal from "pages/Laboratory/TestResult/Detail/TabResult/Modal/GeneralHistoryModal"
import { useSelector } from "react-redux"
import ButtonsVisitHistory from "./ButtonsVisitHistory"

const VisitHistory = ({
  visits,
  paging,
  onGetVisits,
  partyId,
  loadingVisits,
  t,
  updatedVisitsTime,
  onResetPartyVisitsSearchQuery,
  resource,
  onSetPartyVisitsSearchQuery,
  individual,
}) => {
  const [model, setModel] = useState({
    search: "",
  })
  const [generalHistoryModal, setGeneralHistoryModal] = useState(false)
  const onResetHandler = () => {
    const initModel = {
      search: "",
      start: "",
      end: "",
    }
    setModel(initModel)
  }
  const fetchContacts = partyId => {
    onGetVisits({
      query: { page: 1 },
      partyId,
    })
  }

  const onRefreshHandler = () => {
    fetchContacts(partyId)
  }

  const onSizePerPageChange = size => {
    onGetVisits({ query: { page: 1, size }, partyId })
  }
  const onSearch = searchText => {
    onGetVisits({ query: { page: 1, search: searchText }, partyId })
  }
  const onPageChange = page => {
    onGetVisits({ query: { page }, partyId })
  }

  const onSubmitFilter = (values) => {
    onGetVisits({ query: { page: 1, ...values }, partyId })
  }

  const onSortHandler = (field, order) => {
    const sortString = `${field}:${order}`
    onGetVisits({ query: { page: 1, sort: sortString }, partyId })
  }

  useEffect(() => {
    onResetPartyVisitsSearchQuery()
  }, [])

  useEffect(() => {
    if (partyId)
      fetchContacts(partyId)
  }, [partyId])

  const searchQuery = useSelector(state => state.party.searchQueryVisits)

  useEffect(() => {
    setModel(searchQuery)
  }, [searchQuery])


  return (
    <React.Fragment>
      <TitleAndTable
        resource={resource}
        buttons={() => (
          <ButtonsVisitHistory
            resource={resource}
            onRefreshClick={onRefreshHandler}
            onSubmitFilter={onSubmitFilter}
            onResetClick={onResetHandler}
            onClickResult={() => {
              setGeneralHistoryModal(true)
            }}
            model={model}
            data={visits}
            paging={paging}
            partyId = {partyId}
          />
        )}
        table={() => (
          <VisitHistoryTable
            visits={visits}
            onSort={onSortHandler}
            onPageChange={onPageChange}
            paging={paging}
            onSizePerPageChange={onSizePerPageChange}
            loading={loadingVisits}
            updatedTime={updatedVisitsTime}
            resource={resource}
            onRefresh={() => {
              onRefreshHandler()
            }}
            onResetHandler={onResetHandler}
            onSubmitFilter={onSubmitFilter}
            onSearch={onSearch}
            model={model}
            onChangeModel={(val) => {
              onSetPartyVisitsSearchQuery({ ...model, ...val })
            }}
          />
        )}
        external
        subtitle={t("Visit History")}
        icon={false}
      />
      <GeneralHistoryModal
        patientId={partyId}
        modal={generalHistoryModal}
        toggle={() => setGeneralHistoryModal(prev => !prev)}
        title={`${t("General Result History")}`}
        disableSID={true}
        individual={individual}
      />
    </React.Fragment>
  )
}

const mapStateToProps = ({ party, individual }) => ({
  individual: individual.individual,
  visits: party.visits || [],
  contact: party.contact,
  paging: party.pagingVisits,
  loadingVisits: party.loadingVisits,
  updatedVisitsTime: party.updatedVisitsTime,
})

const mapDispatchToProps = dispatch => ({
  onGetVisits: payload => dispatch(getPartyVisitsDetail(payload)),
  onResetPartyVisitsSearchQuery: () => dispatch(resetPartyVisitsSearchQuery()),
  onSetPartyVisitsSearchQuery: () => dispatch(setPartyVisitsSearchQuery()),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation(["partyPage", "message", "common"])(React.memo(VisitHistory)))
