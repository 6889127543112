import {
  Check,
  CustomBootstrapInlineTable,
  CustomSID,
  FormSwitch,
} from "components/Common"
import CustomPopover from "components/Common/Input/CustomPopover"
import {
  InlineEditType,
  ModuleIds,
  RESULT_STATE_Text,
  TestRequest_State,
  parameterCode,
  permissionType,
} from "constant"
import { convertDateFormat } from "helpers/utilities"
import * as moment from "moment"
import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import { withTranslation } from "react-i18next"
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"
import {
  editTestRequestSampleId,
  setRequestSampleClear,
  setRequestSampleCollectTime,
  setRequestSampleCollector,
  setRequestSampleQuality,
  setRequestSampleReceivedTime,
  setRequestSampleReceiver,
  setRequestSampleSID,
} from "store/actions"

const SampleTabTable = ({
  onSelect,
  onSelectAll,
  data,
  onEdit,
  onDelete,
  t,
  loading,
  onSetRequestSampleCollector,
  onSetRequestSampleCollectTime,
  onSetRequestSampleQuality,
  onSetRequestSampleReceiver,
  onSetRequestSampleReceivedTime,
  onSetRequestSampleSID,
  onChange,
  onEditTestRequestSampleId,
  generalSetting,
  testRequest,
  onSetRequestSampleClear,
  onChangeRequestSampleTableSuccess,
  userCollect,
  userCollectName,
  isCollected,
  lstQuanlity,
  ...rest
}) => {
  const [subSampleData, setSampleData] = useState([])
  const RESOURCE = ModuleIds.PatientVisit
  const stateConditionCollected = (state, isClear) => {
    if (state != RESULT_STATE_Text.Submitted && state != RESULT_STATE_Text.RDS)
      return false
    return (state <= TestRequest_State.Collected && state != RESULT_STATE_Text.Canceled && isClear)
      || (state == TestRequest_State.Onhold && isClear)
  }

  useEffect(() => {
    const samples = data.map(x => ({ ...x, isClear: x.isClear !== undefined ? x.isClear : (x.collectionTime && x.collectorUserId) })).filter(item => item.state != 2)
    setSampleData(samples)
  }, [data])

  const isCancel = (code) => code?.state === RESULT_STATE_Text.Canceled ? { textDecoration: 'line-through' } : {}

  const displayCollected = generalSetting.find(item => item.fieldName === 'Collected')?.fieldValue === 'True';

  let widthColumn = 0

  if (displayCollected) {
    widthColumn + 30
  }
  const columns = [
    {
      dataField: "id",
      text: "id",
      hidden: true,
      searchable: false,
    },
    {
      dataField: "idx",
      text: "#",
      searchable: false,
      style: { fontSize: 12 },
      headerStyle: { width: "50px", fontSize: 12 },
      formatter: (cellContent, item, index) => {
        return index + 1
      },
    },
    {
      dataField: "",
      text: "",
      searchable: false,
      headerStyle: { width: "10px" },
      formatter: (cellContent, item, index) => {
        return (
          <>
            <FormSwitch
              label=""
              value={item.isClear !== undefined ? item.isClear : (item.collectorName != undefined && item.collectorName != null && item.collectorName != '')}
              disabled={
                (subSampleData.length > 1
                  && item.state != RESULT_STATE_Text.Canceled)
                  && item.state == RESULT_STATE_Text.Submitted
                  || (item.state == TestRequest_State.Onhold && subSampleData.length > 1) ? false : true
              }
              onChangeSwitch={value => {
                subSampleData.forEach(x => {
                  if (
                    x.sid === item.sid &&
                    x.sampleType === item.sampleType && (!x.subSID || (x.subSID === item.subSID))
                  ) {
                    x.isClear = value
                  }
                })
                let collectedDateString = moment(new Date()).format(
                  "YYYY-MM-DDTHH:mm"
                )
                if (value == false) {
                  item.collectionTime = null
                  item.collectorUserId = null
                  item.collectorName = ""
                } else {
                  item.collectionTime = collectedDateString
                  item.collectorUserId = userCollect
                  item.collectorName = userCollectName
                }
                item.qualityName = lstQuanlity.find(x => x.value == "G")?.label
                onSetRequestSampleClear({ item, value })
                onChange && onChange()
                setSampleData([...subSampleData])
              }}
            />
          </>
        )
      },
    },
    {
      dataField: "sampleType",
      text: t("patientVisitPage:Sample Code"),
      style: { padding: "0px", fontSize: 12 },
      headerStyle: {
        width: `${widthColumn + 70}px`,
        padding: "10px 0px",
        fontSize: 12,
      },
      formatter: (cellContent, item, index) => {
        return <span style={isCancel(item)}>{cellContent}</span>
      },
    },
    {
      dataField: "sampleTypeName",
      text: t("Sample"),
      style: { padding: "0px", fontSize: 12 },
      headerStyle: {
        width: `${widthColumn + 80}px`,
        padding: "10px 0px",
        fontSize: 12,
      },
      formatter: (cellContent, item, index) => {
        return <span style={isCancel(item)}>{cellContent}</span>
      },
    },
    {
      dataField: "sampleColor",
      text: "",
      style: { padding: "0px", fontSize: 12, textAlign: "center" },
      headerStyle: { padding: "0px 0px", fontSize: 12, width: "30px" },
      formatter: (cellContent, item, index) =>
        item?.sampleColor && (
          <i style={{ color: item?.sampleColor }} className="fas fa-circle"></i>
        ),
    },
    {
      dataField: "sid",
      text: t("SID"),
      style: { padding: "0px", textAlign: "center" },
      headerStyle: {
        padding: "10px 0px",
        width: `${widthColumn + 80}px`,
        textAlign: "center",
      },
      formatter: (cellContent, item, index) => {
        return (
          <span style={isCancel(item)}>
            <CustomSID
              sid={`${item.sid}${item.subSID !== 0 ? "-" + item.subSID : ""}`}
              textOnly={true}
            />
          </span>
        )
      },
    },
    // {
    //   dataField: "subSID",
    //   style: { padding: "0px", textAlign: "center", fontSize: 12 },
    //   headerStyle: {
    //     padding: "10px 0px",
    //     textAlign: "center",
    //     width: `${widthColumn + 60}px`,
    //     fontSize: 12,
    //   },
    //   text: t("SubSID"),
    //   formatter: (cellContent, item, index) => {
    //     return (
    //       <span style={isCancel(item)}>
    //         {cellContent != 0 && cellContent}
    //       </span>
    //     )
    //   },
    // },
    ...(displayCollected
      ? [
        {
          dataField: "collectorName",
          text: t("Collector"),
          headerStyle: {
            width: "180px",
            textAlign: "center",
            padding: "10px 10px",
            fontSize: 12,
          },
          style: {
            padding: "10px 10px",
            fontSize: 12,
            textAlign: "center",
          },
          align: "left",
          headerAlign: "left",
          formatter: (cellContent, item, index) => {
            return (
              <React.Fragment>
                {stateConditionCollected(item.state, item.isClear) ? (
                  <div onClick={e => e.stopPropagation()}>
                    <CustomPopover
                      isSearch={true}
                      code={InlineEditType.TESTREQUEST_SAMPLE_COLLECTOR}
                      title={item.collectorName}
                      valueName={item.collectorName}
                      value={`${item.collectorUserId}`}
                      onChange={value => {
                        onSetRequestSampleCollector({ item, value })
                        onChange && onChange()
                      }}
                    />
                  </div>
                ) : (
                  <span>
                    {item.isCollected &&
                      item.collectorName &&
                      item.isClear &&
                      item.state !== RESULT_STATE_Text.Canceled &&
                      item != RESULT_STATE_Text.Submitted
                      ? item.collectorName
                      : "---"}
                  </span>
                )}
              </React.Fragment>
            )
          },
        },
      ]
      : []),
    ...(displayCollected
      ? [
        {
          dataField: "collectionTime",
          text: t("Collection Time"),
          headerStyle: {
            width: "150px",
            padding: "10px 0px",
            fontSize: 12,
            textAlign: "center",
          },
          style: {
            padding: "10px 0px",
            fontSize: 12,
            textAlign: "center",
          },
          align: "left",
          headerAlign: "left",
          formatter: (cellContent, item, index) => (
            <React.Fragment>
              {
                <Check
                  permission={permissionType.CollectedTime}
                  resource={RESOURCE}
                  fallback={
                    <span>
                      {item.isCollected &&
                        item.collectionTime &&
                        item.isClear &&
                        item.state !== RESULT_STATE_Text.Canceled
                        ? convertDateFormat(item.collectionTime)
                        : "---"}
                    </span>
                  }
                >
                  <div onClick={e => e.stopPropagation()}>
                    <CustomPopover
                      type={"datetime"}
                      code={InlineEditType.TESTREQUEST_SAMPLE_TIME}
                      title={convertDateFormat(item.collectionTime,'HH:mm DD-MM-YYYY')}
                      valueName={item.collectionTime}
                      dateSelectFormat={'H:i d-m-Y'}
                      value={`${item.collectionTime}`}
                      namepopover="dateTime_collectionTime"
                      dateFormat='HH:mm DD-MM-YYYY'
                      onChange={values => {
                        let value = moment(values[0]).format(
                          "YYYY-MM-DDTHH:mm"
                        )
                        onSetRequestSampleCollectTime({ item, value })
                        onChange && onChange()
                      }}
                    />
                  </div>
                </Check>
              }
            </React.Fragment>
          ),
        },
      ]
      : []),
    {
      dataField: "qualityName",
      text: t("Quality"),
      style: { padding: "0px", textAlign: "center", fontSize: 12 },
      headerStyle: {
        padding: "10px 0px",
        textAlign: "center",
        fontSize: 12,
        width: `${widthColumn + 75}px`,
      },
      formatter: (cellContent, item, index) => {
        return (
          <>
            {stateConditionCollected(item.state, item.isClear) ? (
              <CustomPopover
                code={parameterCode.TESTREQUEST_SAMPLE_QUALITY}
                title={item.qualityName?.trim()}
                valueName={item.qualityName}
                value={`${item.quality}`}
                onChange={value => {
                  onSetRequestSampleQuality({ item, value })
                  onChange && onChange()
                }}
              />
            ) : item.qualityName &&
              item.isClear &&
              item.isCollected &&
              item.state !== RESULT_STATE_Text.Canceled ? (
              item.qualityName
            ) : (
              "---"
            )}
          </>
        )
      },
    },
  ]

  return (
    <>
      <CustomBootstrapInlineTable
        columns={columns}
        data={subSampleData}
        onSelect={onSelect}
        onSelectAll={onSelectAll}
        isScrollable
        loading={loading}
        searchText={""}
        draggable
        keyField={"sampleType"}
        {...rest}
      />
    </>
  )
}

SampleTabTable.propTypes = {
  onSetRequestSampleCollector: PropTypes.func,
  onSetRequestSampleCollectTime: PropTypes.func,
  onSetRequestSampleQuality: PropTypes.func,
  onSetRequestSampleClear: PropTypes.func,
}

const mapStateToProps = ({ generalSetting, testRequest }) => ({
  testRequest: testRequest.testRequest,
  generalSetting: generalSetting.generalSetting,
})

const mapDispatchToProps = dispatch => ({
  onSetRequestSampleClear: payload => dispatch(setRequestSampleClear(payload)),
  onSetRequestSampleCollector: (payload) => dispatch(setRequestSampleCollector(payload)),
  onSetRequestSampleSID: (payload, callback) => dispatch(setRequestSampleSID(payload, callback)),
  onSetRequestSampleCollectTime: (payload) => dispatch(setRequestSampleCollectTime(payload)),
  onSetRequestSampleQuality: (payload) => dispatch(setRequestSampleQuality(payload)),
  onSetRequestSampleReceiver: (payload) => dispatch(setRequestSampleReceiver(payload)),
  onSetRequestSampleReceivedTime: (payload) => dispatch(setRequestSampleReceivedTime(payload)),
  onEditTestRequestSampleId: payload => dispatch(editTestRequestSampleId(payload)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(withTranslation(["testRequestPage", "common", "patientVisitPage"])(SampleTabTable)))
