import { AvField } from "availity-reactstrap-validation"
import classnames from "classnames"
import {
    getAllTestProfiles,
    getAllTests,
    getAllUsers,
    getAllUsersWithoutError,
    getCodesByParameterId
} from "helpers/app-backend"
import { debounce } from "lodash"
import PropTypes from "prop-types"
import {
    useCallback,
    useEffect,
    useReducer,
    useRef,
    useState,
} from "react"
import Select from "react-select"

import { useDetectedChanges } from "helpers/hooks"
import { getI18nextLng, isEmptyArray } from "helpers/utilities"

import { parameterCode, TestProfileTypeText } from "constant/utility"
import { isEmpty } from "lodash"
import { withTranslation } from "react-i18next"
import { Label } from "reactstrap"
import { RollbackButton } from "."

let valueMultiSelect = [];
const CustomMultiSelectAsync = ({
    value,
    name,
    required,
    errorMessage,
    code,
    group,
    onChange,
    isDependent,
    isMulti,
    portal,
    label,
    detected,
    options,
    readOnly,
    valueName,
    customSearchQuery,
    placeholder,
    isWarning,
    prevData,
    t
}) => {
    const [defaultValue, setDefaultValue] = useState()
    const [inputValue, setInputValue] = useState()
    const inputId = new Date().getTime()
    const [unSelected, setUnSelected] = useState()
    const [searchText, setSearchText] = useState(customSearchQuery)

    const [state, setState] = useReducer(
        (state, newState) => ({ ...state, ...newState }),
        { loading: false, items: [] }
    )

    const inputRef = useRef()
    const [valueInput, isChanged] = useDetectedChanges(
        value,
        isMulti ? defaultValue : defaultValue?.[0]
    )

    const onUndoHandler = () => {
        onInputChangeHandler(inputValue)
        onChangeHandler(
            isMulti ? (isEmpty(valueInput) ? [] : valueInput) : { value: valueInput }
        )
    }

    const isTouched = inputRef.current?.FormCtrl.isTouched()
    const isDirty = inputRef.current?.FormCtrl.isDirty()
    const isInvalid =
        !readOnly &&
        required &&
        isEmptyArray(defaultValue) &&
        (isTouched || isDirty || isWarning)
    let lang = getI18nextLng()

    // COMMON CODES
    const fetchCodes = async (code, group) => {
        const query = { lang, group }
        const res = await getCodesByParameterId(code, query)
        res?.map(_item => {
            _item.value = _item.code
            _item.label = _item.message
            return _item
        })

        return res
    }

    const fetchTestProfileCode = async (searchQuery, checkHasChildren = false) => {
        let query = { size: 15, status: 1, checkHasChildren }
        if (typeof (searchQuery) === typeof ({})) {
            query = { ...query, ...searchQuery }
        }
        else {
            query = { ...query, search: searchQuery }
        }
        const res = await getAllTestProfiles(query)
        let data = []
        if (res.data)
            data = res.data.map(_item => {
                _item.value = _item.code
                _item.label = _item.profileName
                _item.isProfile = true
                _item.testProfileType = _item.type
                return _item
            })
        return data
    }

    const fetchUsers = async keySearch => {
        const query = { size: 0, search: keySearch }
        const res = await getAllUsers(query)
        let data = []
        if (res.data)
            data = res.data.map(_item => {
                _item.value = _item.id
                _item.label = _item.familyName + " " + _item.givenName
                return _item
            })
        if (valueName && valueName !== "" && data.findIndex(x => x.value === value) < 0) {
            data.unshift({
                value: value,
                label: valueName
            })
        }

        const dataUser = [...data, ...prevData];
        const result = [...new Map(dataUser.map(x => [`${x['value']}`, x])).values()];

        return result;
    }

    const fetchUsersWithoutError = async keySearch => {
        const query = { size: 0, search: keySearch }
        const res = await getAllUsersWithoutError(query)
        let data = []
        if (res.data)
            data = res.data.map(_item => {
                _item.value = _item.id
                _item.label = _item.familyName + " " + _item.givenName
                return _item
            })
        if (valueName && valueName !== "" && data.findIndex(x => x.value === value) < 0) {
            data.unshift({
                value: value,
                label: valueName
            })
        }

        const dataUser = [...data, ...prevData];
        const result = [...new Map(dataUser.map(x => [`${x['value']}`, x])).values()];

        return result;
    }

    const fetchTestCode = async searchQuery => {
        let query = { size: 15, inUse: true }
        if (typeof (searchQuery) === typeof ({})) {
            query = { ...query, ...searchQuery }
        }
        else {
            query = { ...query, search: searchQuery }
        }
        const res = await getAllTests(query)
        let data = []
        if (res.data)
            data = res.data.map(_item => {
                _item.value = _item.testCode
                _item.label = _item.testCode + " - " + _item.testName
                _item.isTestCode = true
                _item.testProfileType = 0
                return _item
            })
        return data
    }

    const fetchTestCodeNotInProfile = async searchQuery => {
        let query = { size: 15, inUse: true }
        if (typeof (searchQuery) === typeof ({})) {
            query = { ...query, ...searchQuery }
        }
        else {
            query = { ...query, search: searchQuery }
        }
        const res = await getAllTests(query)
        let data = []
        if (res.data)
            data = res.data.filter(x => x.code == undefined || x.code == null || x.code == '').map(_item => {
                _item.value = _item.testCode
                _item.label = _item.testCode + " - " + _item.testName
                _item.isTestCode = true
                _item.testProfileType = 0
                return _item
            })
        return data
    }


    const fetchProfileAndTestCode = async searchQuery => {
        let dataTest = await fetchTestCodeNotInProfile(searchQuery)
        let dataProfile = await fetchTestProfileCode(searchQuery, true)
        let dataTestAndProfile = [...dataTest, ...dataProfile, ...prevData]
        return dataTestAndProfile
    }

    const fetchOptions = useCallback(async (code, group, value) => {
        setState({ loading: true })
        let res = []
        if (code === parameterCode.TESTPROFILE_CODE) {
            res = await fetchTestProfileCode(value)
        } else if (code === parameterCode.TEST_CODE) {
            res = await fetchTestCode(value)
        } else if (code === parameterCode.PROFILEANDTEST_CODE) {
            res = await fetchProfileAndTestCode(value)
        } else if (code === parameterCode.USERS) {
            res = await fetchUsers(value)
        } else if (code === parameterCode.USERS_Without_Error) {
            res = await fetchUsersWithoutError(value)
        } else {
            res = await fetchCodes(code, group)
        }

        // setItems(data)
        setState({ items: res, loading: false })
    }, [prevData])

    const onChangeHandler = (e, b) => {
        // setSearchText("")
        const element = document.getElementById(name + "" + inputId)
        var event = new Event("change", { bubbles: true })

        const value =
            (isMulti
                ? (Array.isArray(e) ? e?.map(_value => {
                    return _value.value
                }) : (typeof e === 'string' ? e.split(',') : []))
                : [e?.value]) || []
        element.value = value || ""
        element.dispatchEvent(event)
        setDefaultValue(value || "")
        valueMultiSelect = value
        onChange(name, value, state.items?.map(x => x?.label), state.items?.filter(x => `${x.value}` == `${value}`), e, unSelected)
    }
    const isValidStyle = isInvalid && {
        borderColor: "#f46a6a !important",
        backgroundImage: `url(
    "data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23f46a6a'><circle cx='6' cy='6' r='4.5'/><path stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/><circle cx='6' cy='8.2' r='.6' fill='%23f46a6a' stroke='none'/></svg>"
  )`,
        backgroundPosition: "right 2.75rem center, center right 0.5rem",
        backgroundSize: "17px 18px, calc(0.75em + 0.47rem) calc(0.75em + 0.47rem)",
        backgroundRepeat: "no-repeat",
    }

    const colourStyles = {
        control: (styles, { data, isDisabled, isFocused, isSelected }) => ({
            ...styles,
            backgroundColor: isDisabled ? "#edf1f2 !important" : "white",
            fontSize: "13px",
            cursor: isDisabled ? "not-allowed" : "default",
            ...isValidStyle,
            // ...isVaild,
        }),
        option: (styles, { data, isDisabled, isFocused, isSelected }) => {
            // const color = chroma(data.color);
            return {
                ...styles,
                fontSize: "13px",
                // color: "black",
                // backgroundColor: isDisabled ? null : isSelected ? data.color : null,
                cursor: isDisabled ? "not-allowed" : "default",
            }
        },
        singleValue: (provided, state) => {
            const opacity =
                state.isDisabled || !state.data.value || state.data.value === "0"
                    ? 0.7
                    : 1
            const transition = "opacity 300ms"

            return { ...provided, opacity, transition }
        },

        menuPortal: provided => ({
            ...provided,
            zIndex: 9999,
        }),
        menu: provided => ({ ...provided, zIndex: 9999 }),
    }

    const onInputChangeHandler = debounce(value => {
        // setSearchText(value)
        if (value?.length > 0 && code && (!isDependent || (isDependent && group))) {
            setState({ loading: true })
            fetchOptions(code, group, value)
        }
    }, 600)

    useEffect(() => {
        if (code && isDependent && group) fetchOptions(code, group)
        else setState({ items: [] })
    }, [code, group, isDependent])

    useEffect(() => {
        onInputChangeHandler(inputValue)
    }, [inputValue, code, group, isDependent])

    useEffect(() => {
        setDefaultValue(isMulti ? value : [value])
        setInputValue(valueName)
    }, [value, valueName])

    useEffect(() => {
        if (!isEmpty(options)) {
            if (valueName && valueName !== "" && options.findIndex(x => x.value === value) < 0) {
                options.unshift({
                    value: value,
                    label: valueName
                })
            }
            setState({ items: options })
        }
    }, [options])
    useEffect(() => {
        if (code)
            fetchOptions(code, group, customSearchQuery)
        else setState({ items: [] })
    }, [customSearchQuery])

    const CustomCheckboxOption = ({ innerProps, label, isSelected, isDisabled, ...rest }) => {
        return (
            <div {...innerProps} >
                <div className="check-option px-2" style={{ cursor: 'pointer', display: 'flex', justifyContent: 'space-between', minHeight: '35px', alignItems: 'center' }}>
                    <div style={{ display: 'flex', gap: '5px', alignItems: 'center' }}>
                        <span>{label}</span>
                        {rest?.data?.testProfileType == TestProfileTypeText.TEST &&
                            <div className="px-2 d-flex badge-test">
                                <span style={{ fontSize: '10px' }}>Test</span>
                            </div>
                        }
                        {rest?.data?.testProfileType === TestProfileTypeText.PROFILE &&
                            <div className="px-2 d-flex badge-profile">
                                <span style={{ fontSize: '10px' }}>Profile</span>
                            </div>
                        }
                        {rest?.data?.testProfileType === TestProfileTypeText.GROUP &&
                            <div className="px-2 d-flex badge-group">
                                <span style={{ fontSize: '10px' }}>Group</span>
                            </div>
                        }
                    </div>
                    {isMulti && <input
                        type="checkbox"
                        checked={isSelected}
                        onChange={() => {
                            if (isSelected) {
                                setUnSelected(rest?.value)
                            }
                        }}
                        disabled={isDisabled}
                    />}
                </div>
            </div >)
    };

    return (
        <>
            <div className="label-group-relative position-relative">
                {label && (
                    <Label for={name}>
                        {label}
                        {required && <span className="text-danger">*</span>}
                    </Label>
                )}

                <RollbackButton
                    display={isChanged && detected}
                    onClick={onUndoHandler}
                />
            </div>
            <Select
                closeMenuOnSelect={false}
                isClearable
                placeholder={placeholder || ''}
                hideSelectedOptions={false}
                isLoading={state.loading}
                name="select-customasync"
                openMenuOnFocus
                id={name + "" + "select-customasync"}
                onInputChange={onInputChangeHandler}
                menuPlacement="auto"
                maxMenuHeight={150}
                menuPortalTarget={!portal && document.body}
                styles={colourStyles}
                isDisabled={readOnly}
                noOptionsMessage={() => t("No options")}
                loadingMessage={() => t("Loading...")}
                isMulti
                value={state.items.filter(
                    _item =>
                        Array.isArray(defaultValue) &&
                        defaultValue?.findIndex(
                            _defaultValue =>
                                JSON.stringify(_defaultValue + "") ===
                                JSON.stringify(_item.value + "")
                        ) >= 0
                )}
                onChange={onChangeHandler}
                options={state.items}
                classNamePrefix="select2-selection"
                className={classnames(
                    { "has-changed": isChanged && detected },
                    "form-select2 is-touched is-dirty av-invalid is-invalid"
                )}
                components={{
                    Option: ({ innerProps, isSelected, ...rest }) => (<CustomCheckboxOption {...rest} isSelected={isSelected} innerProps={innerProps} />)
                }}
            />
            {isInvalid && (
                <div className="text-danger form-group">
                    <div className="is-touched is-dirty av-invalid is-invalid"></div>
                    <div className="invalid-feedback">{errorMessage}</div>
                </div>
            )}
            <div className="d-none">
                <AvField
                    multiple={isMulti}
                    name={name}
                    id={name + "" + inputId}
                    type="select"
                    className={`form-select`}
                    value={isMulti ? defaultValue : defaultValue?.[0]}
                    errorMessage={errorMessage}
                    required={!readOnly && required}
                    ref={inputRef}
                    readOnly={readOnly}
                >
                    <option value="">--Select--</option>
                    {state.items.map((_item, idx) => (
                        <option key={idx} value={_item.value}>
                            {_item.label}
                        </option>
                    ))}
                </AvField>
            </div>
        </>
    )
}

CustomMultiSelectAsync.propTypes = {
    onChange: PropTypes.func,
    name: PropTypes.string.isRequired,
    isDependent: PropTypes.bool,
    required: PropTypes.bool,
    errorMessage: PropTypes.string,
    value: PropTypes.any,
    valueName: PropTypes.string.isRequired,
    group: PropTypes.string,
    code: PropTypes.string,
    isMulti: PropTypes.bool,
    portal: PropTypes.bool,
    label: PropTypes.any.isRequired,
    detected: PropTypes.bool,
    options: PropTypes.array,
    readOnly: PropTypes.bool,
    t: PropTypes.any
}

CustomMultiSelectAsync.defaultProps = {
    name: "",
    onChange: () => { },
    isDependent: false,
    errorMessage: "This field is invalid",
    portal: false,
    valueName: ""
}

export default withTranslation(["common"])(CustomMultiSelectAsync)
