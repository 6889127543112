import classnames from "classnames"
import { Fragment, useEffect, useState } from "react"
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"
import { withTranslation } from "react-i18next"
import {
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane
} from "reactstrap"
import {
  getRequestSampleTrackingList,
  setSampleTrackingSearchQuery,
} from "store/actions"
import CustomSearchBar from "./SearchBar"
import TabAllTest from "./TabAllTest"
import TabResultTest from "./TabResultTest"
import TabSampledTest from "./TabSampledTest"
import { CustomSelect } from "components/Common"
import { parameterCode } from "constant"
import { AvForm } from "availity-reactstrap-validation"

const TabTestInternal = ({
  requestSampleTrackings,
  onGetRequestSampleTrackings,
  isSendTest,
  // onSetSampleTrackingSearchQuery,
  // requestSampleTrackingsCollected,
  // requestSampleTrackingsValidated,
  // requestSampleTrackingsConfirmed,
  // requestSampleTrackingsOnHold,
  // requestSampleTrackingsCompleted,
  t
}) => {
  const [searchText, setSearchText] = useState("")
  const [activeTab, setactiveTab] = useState("1")

  const toggleTab = tab => {
    if (activeTab !== tab) {
      setactiveTab(tab)
    }
  }

  // const onFetchRequestSampleTrackings = () => {
  //   onGetRequestSampleTrackings({ size: 0 })
  // }
  
  useEffect(() => {
    // if (isSendTest) {
      onGetRequestSampleTrackings({ size: 0, isSendTest });
    // } else {
    //   onGetRequestSampleTrackings({ size: 0 });
    // }
  }, [isSendTest]);

  // useEffect(() => {
  //   onFetchRequestSampleTrackings()
  // }, [])

  return (
    <Fragment>
      <div className="d-flex flex-wrap flex-row">
        <div style={{ width: "50%"}}>
          <CustomSearchBar
            height= "38px"
            placeholder={t("Search(SID, PID, Patient Name)")}
            searchText={searchText}
            width={"100%"}
            onSearch={e => {
              setSearchText(e.target.value)
            }}
            onClear={() => {
              setSearchText("")
              onGetRequestSampleTrackings({ search: "", size: 0 , isSendTest})
            }}
            onSubmit={() => {
              onGetRequestSampleTrackings({ search: searchText, size: 0, isSendTest })
            }}
          />
        </div>
        <div style={{ width: "49%", height : '32px' , marginLeft : '5px' }}>
        <AvForm>
            <CustomSelect
              label={""}
              placeholder={t("State")}
              portal
              name="state"
              code={parameterCode.TESTREQUEST_STATE_KEY}
              onChange={(name, value, label, item) => {
                    onGetRequestSampleTrackings({ state: value[0] || null, search: searchText, size: 0 , isSendTest})
              }}
            />   
        </AvForm>   
        </div> 
        <Nav
          pills
          className="navtab-bg nav-justified"
          style={{
            border: "1px solid #ccc",
            borderRadius: 6,
            width: "100%",
            marginTop: 10,
          }}
        >
          <NavItem>
            <NavLink
              style={{
                cursor: "pointer",
                color: activeTab === "1" ? "#fff" : "#ccc",
              }}
              className={classnames({
                active: activeTab === "1",
              })}
              onClick={() => {
                toggleTab("1")
              }}
            >
              {t("ALL")}
            </NavLink>
          </NavItem>
        </Nav>

        <TabContent
          activeTab={activeTab}
          className="p-1 text-muted"
          style={{ width: "100%" }}
        >
          <TabPane tabId="1">
            <TabAllTest data={requestSampleTrackings} isSendTest = {isSendTest}/>
          </TabPane>
        </TabContent>
      </div>
    </Fragment>
  )
}

const mapStateToProps = ({ sampleTracking }) => ({
  requestSampleTrackings: sampleTracking.requestSampleTrackings,
  requestSampleTrackingsCollected:
    sampleTracking.requestSampleTrackingsCollected,
  requestSampleTrackingsValidated: sampleTracking.requestSampleTrackingsValidated,
  requestSampleTrackingsConfirmed: sampleTracking.requestSampleTrackingsConfirmed,
  requestSampleTrackingsOnHold: sampleTracking.requestSampleTrackingsOnHold,
  requestSampleTrackingsCompleted: sampleTracking.requestSampleTrackingsCompleted,
})

const mapDispatchToProps = dispatch => ({
  onGetRequestSampleTrackings: payload =>
    dispatch(getRequestSampleTrackingList(payload)),
  onSetSampleTrackingSearchQuery: payload =>
    setSampleTrackingSearchQuery(payload),
})

export default connect(mapStateToProps, mapDispatchToProps)
(withRouter(withTranslation(["testConfig"])(TabTestInternal)))

