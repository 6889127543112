import { LabelAndValue, TitleAndInformation } from "components/Common"
import { convertDateFormat, convertDateTimeFormat } from "helpers/utilities"
import PropTypes from "prop-types"
import { withTranslation } from "react-i18next"
import ReactTooltip from "react-tooltip"
import { Col, Row } from "reactstrap"

const TitleAndInfo = ({
  onEdit,
  onClone,
  data,
  statistic,
  resource,
  t,
  ...rest
}) => {
  return (
    <TitleAndInformation
      onEdit={onEdit}
      onClone={onClone}
      resource={resource}
      {...rest}
    >
      <Row>
        <Col xs="12" md="12">
          <Row>
            <Col xs="auto" className="pr-6">
              <LabelAndValue label={t("Patient Id")} value={"#" + data.PatientId} />
            </Col>
            <Col xs="auto" className="pr-6">
              <LabelAndValue
                label={t("common:Telephone")}
                value={statistic.telephone}
              />
            </Col>
            <Col xs="auto" className="pr-6">
              <LabelAndValue
                label={t("common:DOB")}
                value={convertDateTimeFormat(data.DOB)}
              />
            </Col>
            <Col xs="auto" className="pr-6">
              <LabelAndValue
                label={t("common:Age")}
                value={new Date().getFullYear() - new Date(data.DOB).getFullYear()}
              />
            </Col>
            <Col xs="auto" className="pr-6">
              <LabelAndValue
                label={t("common:Gender")}
                value={t(data.GenderName)}
              />
            </Col>
            <Col xs="auto" className="pr-6">
              <LabelAndValue
                label={t("Hospital/Clinic Mngt.")}
                value={data.ManagementCompanyName}
                linkTo={`/Company/${data.ManagementCompanyId}/view?tab=1`}
              />
            </Col>
            <Col xs="auto"> <span><i className="bx bx-building-house" style={{ fontSize: "32px",  color: '#adb5bd' }}></i></span></Col>
            <Col xs="auto">
              <LabelAndValue label={t("common:Address")} />
              <div>
                <div style={{ whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }} >
                  <p data-tip data-for={data.Id}>
                    {statistic.address}

                  </p>
                </div>
                {
                  statistic.address.length >= 55 ? < ReactTooltip id={data.Id.toString()} place="bottom-start" effect="solid">
                    {statistic.address}
                  </ReactTooltip> : <></>
                }

              </div>
            </Col>
            <Col xs="auto" className="pr-6">
              <LabelAndValue
                label={t("Last Visited")}
                value={convertDateFormat(data.updatedDate)}
              />
            </Col>
            <Col xs="auto" className="d-flex justify-content-end ms-auto">
              <i className="bx bx-user" style={{ fontSize: '48px', color: '#adb5bd' }}></i>
            </Col>
          </Row>
        </Col>
      </Row >
    </TitleAndInformation >
  )
}

TitleAndInfo.propTypes = {
  onEdit: PropTypes.func,
  onClone: PropTypes.func,
  parameter: PropTypes.object,
  t: PropTypes.any,
}

export default withTranslation(["partyPage", "common"])(TitleAndInfo)
